import React, { useEffect, useState } from "react";
import "./Verify.css";
import "./Ex.css";
import "./Info.css";
import { BasicAccordion_INFO } from "../../CustomComponent/Accordion";
import { GET_DATA, POST_API } from "../../Api/GetData";
import MuiPhoneNumber from "material-ui-phone-number";
import { ERROR, SUCCESS } from "../../CustomComponent/Msg";
import {
  ITS_MEAN,
  getColorCode,
  getFinalStatus,
  isValidUrl,
} from "../../Global/Const";
import { SpinnerLoader } from "../../Global/Icons";
import BankDetailModal from "../../CustomComponent/BankDetailModal";
import ModalWraper from "../../CustomComponent/ModalWraper";
import { Use_Api } from "../../CustomComponent/PureComponent";
import { ReNameRenderObject } from "../../Global/Constant";
import SacntionScreen from "./Sanctions/SacntionScreen";

const Info = ({
  UserId,
  Risk_Matrix_Access,
  showRiskMatrix,
  CDDAccess,
  Get_Score_Data,
  Show_Score,
}) => {
  const [_Id, Set_Id] = useState("");
  const [Sanction, Set_Sanction] = useState("");
  const [Cell_No, Set_Cell_No] = useState("");
  const [address, setAddress] = useState("");
  const [PhotoId, setPhotoId] = useState("");
  const [_IdER, Set_IdER] = useState("");
  const [SanctionER, Set_SanctionER] = useState("");
  const [Cell_NoER, Set_Cell_NoER] = useState("");
  const [addressER, setAddressER] = useState("");
  const [BankEr, setBankEr] = useState("");
  const [PhotoIdER, setPhotoIdER] = useState("");
  const [apicall, setApiCall] = useState(false);
  const [checkCellPhone, setcheckCellPhone] = useState({});
  const [checkId, setcheckId] = useState({});
  const [checkIdAddress, setcheckIdAddress] = useState({});
  const [checkIdPhoto, setcheckIdPhoto] = useState({});
  const [checkBankDetail, setCheckBankDetail] = useState({});
  const [showsanctions, setshowsanctions] = useState({});
  const [loading, setLoading] = useState(false);
  const [isSanctionRan, setIsSanctionRan] = useState(false);
  const [overrideClickable, setOverrideClickable] = useState(true);
  const [open_bankDetailModal, set_Open_Bank_Detail_Modal] = useState(false);
  const { fetchData } = Use_Api("credits");

  const [overRideEr, setOverRideEr] = useState({
    IdNumber: false,
    Saction: false,
    MobileNumber: false,
    Idphoto: false,
    address: false,
    bankDetails: false,
  });
  async function handle_Verify({
    setEr,
    Url,
    ErMsg,
    data,
    checkState,
    Succeess_Msg,
  }) {
    setLoading(false);

    if (checkState) {
      setEr(false);

      if (checkRequiredValues(data)) {
        try {
          const Response = await POST_API(Url, data, Succeess_Msg);
          setApiCall(!apicall);
          setLoading(false);

          Get_Score_Data();
          fetchData();
        } catch (err) {
          // console.log(err.response, "Error");
          setLoading(false);
          fetchData();
          Get_Score_Data();

          if (err.response?.data?.message) {
            ERROR(err.response?.data?.message);
          } else {
            ERROR("Something went wrong.");
          }
        }
      }
    } else {
      setLoading(false);
      setEr(ErMsg);
      ERROR(ErMsg);
    }
  }

  async function verifyId(Url, data, Succeess_Msg) {
    try {
      const Response = await POST_API(Url, data, Succeess_Msg);
      setApiCall(!apicall);
      setLoading(false);

      Get_Score_Data();
      fetchData();
    } catch (err) {
      // console.log("Error");
      setLoading(false);
      fetchData();
      Get_Score_Data();

      setApiCall(!apicall);

      if (err.response?.data?.message) {
        ERROR(err.response?.data?.message);
      } else {
        ERROR("Something went wrong.");
      }
    }
  }
  async function handle_OverRide({ Url, key }) {
    try {
      const Response = await GET_DATA(Url);
      Get_Score_Data();
      SUCCESS(Response?.message);

      setOverRideEr((prev) => ({
        ...prev,
        [key]: false,
      }));
      setLoading(false);
    } catch (err) {
      setLoading(false);

      // console.log(err.response, "Error");
      Get_Score_Data();

      setOverRideEr((prev) => ({
        ...prev,
        [key]: false,
      }));
      if (err.response?.data?.message) {
        ERROR(err.response?.data?.message);
      } else {
        ERROR("Something went wrong.");
      }
    }
  }

  function handleContact(value, country) {
    // console.log(value, country, "number");
    if (value.length < 10) {
      Set_Cell_NoER("Invalid Phone Number.");
    } else {
      Set_Cell_NoER("");
    }

    Set_Cell_No(value);
  }

  function checkRequiredValues(obj) {
    for (const key in obj) {
      if (!obj[key]) {
        // alert(`${key} is required`);
        ERROR(`${ITS_MEAN[key]} is required`);

        return false;
      }
    }
    return true;
  }

  function handle_Close_BankDetail_Modal() {
    set_Open_Bank_Detail_Modal(false);
  }
  useEffect(() => {
    setLoading(false);

    (async () => {
      try {
        const Response = await GET_DATA(`user/get/info/${UserId}`);
        setcheckCellPhone(Response?.data?.checkCellPhone);
        setOverrideClickable(!Response?.data?.overrideClickable);
        setcheckId(Response?.data?.checkId);
        setcheckIdAddress(Response?.data?.checkIdAddress);
        setcheckIdPhoto(Response?.data?.checkIdPhoto);
        setshowsanctions(Response?.data?.sanctions);
        setIsSanctionRan(Response?.data?.sanctionRan);
        setCheckBankDetail(Response?.data?.bankAccount);

        Set_Id(Response?.data?.personalId);
        Set_Sanction(Response?.data?.name);
        setPhotoId(Response?.data?.personalId);
        setAddress(Response?.data?.personalId);
        Set_Cell_No(Response?.data?.phoneNo);

        // Get_Score_Data();
      } catch (err) {
        // console.log(err.response);
      }
    })();
  }, [apicall]);

  return (
    <div className="C_l W_F MT3">
      <ModalWraper
        open={open_bankDetailModal}
        handleClose={handle_Close_BankDetail_Modal}
        setOpen={set_Open_Bank_Detail_Modal}
        handleTableData={(getData) => {
          handle_Verify({
            setEr: setBankEr,
            Url: `user/bankAccount/verification/${UserId}`,
            data: getData,
            ErMsg: "Invalid Bank Details.",
            Succeess_Msg: "Bank Details Verified",
            checkState: "address",
          });
          setLoading(true);
        }}
        Component={BankDetailModal}
        UserId={{
          UserId,
          _Id,
          Sanction,
          setLoading,
        }}
        dl={"dl"}
      />
      <SpinnerLoader state={loading} />

      <div className="Heaer_contaner" style={{ marginBottom: "20px" }}>
        <span className="Heaar_title">Customer Due Diligence</span>
      </div>
      <div className="G-1-F">
        <div className="A_1__">
          <div className="Info_Input_Cont">
            <span className="T_1 cntg_5">
              {getFinalStatus(Show_Score?.idnumberStatus)}
              Verify ID number
              {/* {`(${Show_Score.IdNumber})`} */}
            </span>
          </div>
          {/* <div className="Info_Conatiner"> */}
          <div className="Info_Conatiner" style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <input
                className={
                  _IdER
                    ? "ERSHOW INPUT_CONTAINER_ W_Cus P_Ten"
                    : "INPUT_CONTAINER_ W_Cus P_Ten"
                }
                onChange={(e) => Set_Id(e.target.value)}
                value={_Id}
              />
              {/* </div> */}
            </div>
            {Risk_Matrix_Access &&
              showRiskMatrix &&
              getColorCode(Show_Score.idnumber, Show_Score?.idnumberStatus)}

            {CDDAccess?.edit && (
              <>
                <button
                  className="Active_Button"
                  disabled={overRideEr.IdNumber}
                  style={{
                    background: overRideEr.IdNumber ? "grey" : "#38aee0",
                  }}
                  onClick={() => {
                    setOverRideEr((prev) => ({
                      ...prev,
                      IdNumber: true,
                    }));
                    handle_OverRide({
                      Url: `user/override/id/${UserId}`,
                      key: "IdNumber",
                    });
                    setLoading(true);
                  }}
                >
                  Override
                </button>
                <button
                  id="IndivIDVerify"
                  className="Active_Button"
                  onClick={() => {
                    setLoading(true);

                    verifyId(
                      `user/check/id/${UserId}`,
                      {
                        name: Sanction,
                        idNumber: _Id,
                      },
                      "Id  Number  Verified"
                    );
                  }}
                >
                  Verify
                </button>
              </>
            )}
          </div>
        </div>
        <div className="A_1__ ">
          <div className="Info_Input_Cont">
            <span className="T_1 cntg_5">
              {getFinalStatus(Show_Score?.sanctionStatus)}
              Sanction/PEP Screen
              {/* {`(${Show_Score.sanction})`} */}
            </span>
          </div>
          <div className="Info_Conatiner" style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <input
                className={
                  SanctionER
                    ? "ERSHOW INPUT_CONTAINER_ W_Cus P_Ten"
                    : "INPUT_CONTAINER_ W_Cus P_Ten"
                }
                onChange={(e) => Set_Sanction(e.target.value)}
                value={Sanction}
              />
            </div>
            {Risk_Matrix_Access &&
              showRiskMatrix &&
              getColorCode(Show_Score.sanction, Show_Score?.sanctionStatus)}
            {CDDAccess?.edit && (
              <>
                <button
                  className="Active_Button"
                  disabled={overRideEr.Saction || overrideClickable}
                  style={{
                    background:
                      overRideEr.Saction || overrideClickable
                        ? "grey"
                        : "#38aee0",
                  }}
                  onClick={() => {
                    setLoading(true);

                    setOverRideEr((prev) => ({
                      ...prev,
                      Saction: true,
                    }));
                    handle_OverRide({
                      Url: `user/override/sanction/${UserId}`,
                      key: "Saction",
                    });
                  }}
                >
                  Override
                </button>
                <button
                  className="Active_Button"
                  onClick={() => {
                    handle_Verify({
                      setEr: Set_SanctionER,
                      Url: `user/check/sanction/${UserId}`,
                      ErMsg: "Invalid name.",
                      data: { name: Sanction },
                      checkState: Sanction,
                      Succeess_Msg: "Sanction Verified",
                    });
                    if (Sanction !== "") {
                      setLoading(true);
                    }
                  }}
                >
                  Verify
                </button>
              </>
            )}
          </div>
        </div>
        <div className="A_1__">
          <div className="Info_Input_Cont ">
            <span className="T_1 cntg_5">
              {getFinalStatus(Show_Score?.mobileVerifiedWithDHAStatus)}
              Verify Mobile Number
              {/* {`(${Show_Score.mobileVerifiedWithDHA})`} */}
            </span>
          </div>
          <div className="Info_Conatiner" style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <div
                style={{ padding: "0px 5px" }}
                // className={PhoneEr ? "INPUT_FIELD BRD ER_BR" : "INPUT_FIELD BRD"}
                className={
                  Cell_NoER
                    ? "ERSHOW INPUT_CONTAINER_ W_Cus "
                    : "INPUT_CONTAINER_ W_Cus "
                }
              >
                <MuiPhoneNumber
                  style={{ width: "100%" }}
                  defaultCountry={"za"}
                  countryCodeEditable={false}
                  value={Cell_No}
                  placeholder="Enter your phone number"
                  onChange={handleContact}
                  className="PhonePicker Wid_F"
                />
              </div>
            </div>

            {Risk_Matrix_Access &&
              showRiskMatrix &&
              getColorCode(
                Show_Score.mobileVerifiedWithDHA,
                Show_Score?.mobileVerifiedWithDHAStatus
              )}

            {CDDAccess?.edit && (
              <>
                <button
                  className="Active_Button"
                  disabled={overRideEr.MobileNumber}
                  style={{
                    background: overRideEr.MobileNumber ? "grey" : "#38aee0",
                  }}
                  onClick={() => {
                    setOverRideEr((prev) => ({
                      ...prev,
                      MobileNumber: true,
                    }));
                    handle_OverRide({
                      Url: `user/override/mobile/${UserId}`,
                      key: "MobileNumber",
                    });

                    setLoading(true);
                  }}
                >
                  Override
                </button>

                <button
                  className="Active_Button"
                  onClick={() => {
                    handle_Verify({
                      setEr: Set_Cell_NoER,
                      Url: `user/check/id/cell/${UserId}`,
                      data: {
                        name: Sanction,
                        idNumber: _Id,
                        number: Cell_No,
                      },
                      ErMsg: "Invalid Mobile number.",
                      Succeess_Msg: "Mobile Number Verified",
                      checkState: Cell_No,
                    });
                    if (Cell_No !== "" && _Id !== "") {
                      setLoading(true);
                    }
                  }}
                >
                  Verify
                </button>
              </>
            )}
          </div>
        </div>
        <div className="A_1__">
          <div className="Info_Input_Cont ">
            <span className="T_1 cntg_5">
              {getFinalStatus(Show_Score?.idphotoStatus)} Verify government
              photo with ID document {/* {`(${Show_Score.idphoto})`} */}
            </span>
          </div>
          <div className="Info_Conatiner" style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <input
                className={
                  PhotoIdER
                    ? "ERSHOW INPUT_CONTAINER_ W_Cus P_Ten"
                    : "INPUT_CONTAINER_ W_Cus P_Ten"
                }
                onChange={(e) => {
                  setPhotoId(e.target.value);
                }}
                value={PhotoId}
              />
            </div>
            {Risk_Matrix_Access &&
              showRiskMatrix &&
              getColorCode(Show_Score.idphoto, Show_Score?.idphotoStatus)}

            {CDDAccess?.edit && (
              <>
                <button
                  className="Active_Button"
                  disabled={overRideEr.Idphoto}
                  style={{
                    background: overRideEr.Idphoto ? "grey" : "#38aee0",
                  }}
                  onClick={() => {
                    setOverRideEr((prev) => ({
                      ...prev,
                      Idphoto: true,
                    }));
                    handle_OverRide({
                      Url: `user/override/photo/${UserId}`,
                      key: "Idphoto",
                    });

                    setLoading(true);
                  }}
                >
                  Override
                </button>

                <button
                  className="Active_Button"
                  onClick={() => {
                    handle_Verify({
                      setEr: setPhotoIdER,
                      Url: `user/check/id/photo/${UserId}`,
                      data: { name: Sanction, idNumber: PhotoId },
                      ErMsg: "Invalid Photo Id.",
                      Succeess_Msg: "Id photo Verified",
                      checkState: PhotoId,
                    });
                    if (PhotoId !== "") {
                      setLoading(true);
                    }
                  }}
                >
                  Verify
                </button>
              </>
            )}
          </div>
        </div>
        <div className="A_1__">
          <div className="Info_Input_Cont ">
            <span className="T_1 cntg_5">
              {getFinalStatus(Show_Score?.addressVerificationStatus)}
              Verify address with credit bureau
            </span>
          </div>
          <div className="Info_Conatiner" style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <input
                className={
                  addressER
                    ? "ERSHOW INPUT_CONTAINER_ W_Cus P_Ten"
                    : "INPUT_CONTAINER_ W_Cus P_Ten"
                }
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
                value={address}
              />
            </div>
            {Risk_Matrix_Access &&
              showRiskMatrix &&
              getColorCode(
                Show_Score.addressVerification,
                Show_Score?.addressVerificationStatus
              )}
            {CDDAccess?.edit && (
              <>
                <button
                  className="Active_Button"
                  disabled={overRideEr.address}
                  style={{
                    background: overRideEr.address ? "grey" : "#38aee0",
                  }}
                  onClick={() => {
                    setLoading(true);

                    setOverRideEr((prev) => ({
                      ...prev,
                      address: true,
                    }));
                    handle_OverRide({
                      Url: `user/override/address/${UserId}`,
                      key: "address",
                    });
                  }}
                >
                  Override
                </button>

                <button
                  className="Active_Button"
                  onClick={() => {
                    handle_Verify({
                      setEr: setAddressER,
                      Url: `user/check/id/address/${UserId}`,
                      data: { name: Sanction, idNumber: address },
                      ErMsg: "Invalid Address.",
                      Succeess_Msg: "Address Verified",
                      checkState: address,
                    });
                    if (address !== "") {
                      setLoading(true);
                    }
                  }}
                >
                  Verify
                </button>
              </>
            )}
          </div>
        </div>
        <div className="A_1__">
          <div className="Info_Input_Cont ">
            <span className="T_1 cntg_5">
              {getFinalStatus("pending")}
              Verify bank account
              {/* ({getColorCode(Show_Score.addressVerification)}) */}
              {/* {`(${Show_Score.addressVerification})`} */}
            </span>
            <div
              className={"INPUT_CONTAINER_ W_Cus "}
              style={{ visibility: "hidden" }}
              onClick={(e) => {
                // set_Open_Bank_Detail_Modal(true);
              }}
            ></div>
          </div>
          <div className="Info_Conatiner">
            {Risk_Matrix_Access && showRiskMatrix && getColorCode(0, "pending")}

            {CDDAccess?.edit && (
              <>
                <button
                  className="Active_Button"
                  disabled={overRideEr.bankDetails}
                  style={{
                    background: !overRideEr.bankDetails ? "grey" : "#38aee0",
                  }}
                  onClick={() => {}}
                >
                  Override
                </button>

                <button
                  className="Active_Button"
                  onClick={() => {
                    set_Open_Bank_Detail_Modal(true);
                  }}
                >
                  Verify
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      <BasicAccordion_INFO
        show={renderObject(checkId)}
        open={false}
        title={"Verify ID number Details"}
      />
      <SacntionScreen
        showsanctions={showsanctions}
        Id={UserId}
        isSanctionRan={isSanctionRan}
        Get_Score_Data={Get_Score_Data}
      />
      {/* 
      <BasicAccordion_INFO
        title={"Sanction/PEP Screen Details"}
        show={renderObject(showsanctions)}
        open={false}
      /> */}

      <BasicAccordion_INFO
        show={renderObject(checkCellPhone)}
        open={false}
        title={"Mobile Details"}
      />

      <BasicAccordion_INFO
        open={false}
        show={renderObject(checkIdPhoto)}
        title={"Verify government photo with ID document Details"}
      />

      <BasicAccordion_INFO
        title={"Address Details"}
        show={renderObject(checkIdAddress)}
        open={false}
      />

      <BasicAccordion_INFO
        title={"Verify bank account Details"}
        show={renderObject(checkBankDetail)}
        open={false}
      />
    </div>
  );
};

export default Info;

export function renderObject(obj) {
  if (typeof obj !== "object" || obj === null) {
    return null;
  }

  return (
    <ul>
      {Object.entries(obj).map(([key, value]) => (
        <li key={key}>
          {!Array.isArray(value) &&
          typeof value === "object" &&
          value !== null ? (
            <>
              <strong style={{ textTransform: "capitalize" }}>
                {ReNameRenderObject(key)}:
              </strong>
              {renderObject(value)}
            </>
          ) : Array.isArray(value) ? (
            <>
              <strong style={{ textTransform: "capitalize" }}>
                {ReNameRenderObject(key)}:
              </strong>
              <ul>
                {value.map((element, index) => (
                  <li key={index}>
                    {typeof element === "object" && element !== null
                      ? renderObject(element)
                      : `${element}`}
                  </li>
                ))}
              </ul>
            </>
          ) : key === "IDPhoto" ? (
            <div className="PHOTO_ID">
              <strong>{ReNameRenderObject(key)}:</strong>
              <img
                src={value}
                alt="ID Photo"
                className="InfoIMG"
                style={{ width: "20rem", objectFit: "contain" }}
              />
            </div>
          ) : (
            <>
              <strong style={{ textTransform: "capitalize" }}>
                {ReNameRenderObject(key)}:
              </strong>{" "}
              {typeof value === "boolean"
                ? JSON.stringify(value)
                : makeHyperLink(value)}
            </>
          )}
        </li>
      ))}
    </ul>
  );
}

export function makeHyperLink(link) {
  if (isValidUrl(link)) {
    return (
      <a href={link} style={{ color: "blue" }} target="_blank">
        {link}
      </a>
    );
  } else {
    return link;
  }
}
export function USEIT(obj, key) {
  if (typeof obj !== "object" || obj === null) {
    return null;
  }

  return (
    <ol index={key} className="dubug_Internal_container">
      {Object.entries(obj).map(([key, value]) => (
        <li
          key={key}
          style={{
            display:
              typeof value === "object" && value !== null ? "block" : "flex",
            flexDirection: "row",
            gap: "5px",
          }}
        >
          <strong style={{ color: "#e39f44" }}>"{key}" :</strong>{" "}
          <span style={{ color: "#3f987f " }}>
            {typeof value === "object" && value !== null
              ? USEIT(value, key)
              : typeof value === "boolean"
              ? JSON.stringify(value)
              : `"${value}"`}
          </span>
        </li>
      ))}
    </ol>
  );
}
