import {
  CAMERAICON,
  DOCUMENT,
  EARTHICON,
  SELFIVERIFY,
  FEEDBACK,
  PHONE,
  EMIALICONS,
  MONEY_ICON,
  SpinnerLoader,
} from "./Icons";
import isUrl from "is-url";
import "./Const.css";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import moment from "moment";
import { ToolTipWrapper } from "./ToolTipWraper";
import { makeHyperLink } from "../Pages/Verificatoin_Detail/Info";
import { useEffect, useState } from "react";
import CustomCheckbox from "../components/forms/custom-elements/CustomCheckbox";
export const BreadCrubm = ({ PageName, ID }) => (
  <div className="DFlx RspD FI_SP" style={{ width: "100%" }}>
    <div className="Start_Flx ">
      {PageName &&
        PageName?.map((data, index) => (
          <div className="DFlx" key={index}>{`${
            data !== undefined && data
          } >`}</div>
        ))}
    </div>
    <div>{ID}</div>
  </div>
);
export const ERMSG = "Something went wrong";
export function isObjectEmpty(obj) {
  if (obj !== null && obj !== undefined) {
    if (obj.email) return true;
  } else {
    return false;
  }
}

export const UTC_TO_DD_MM_YYYY = (none_formated_date) => {
  const formatted = moment(none_formated_date).format("YYYY-MM-DD");
  return formatted;
};

export function CONVER_TIME_DATE(time) {
  const date = moment(Number(time));
  const localTime_Date = date.format("dddd, MMMM Do YYYY, h:mm:ss a");
  return localTime_Date;
}

export function GET_STATUS_RESULT(status) {
  if (status !== undefined) {
    switch (status?.toLowerCase()) {
      case "true":
        return "Passed";
      case "passed":
        return "Passed";
      case "approved":
        return "Approved";
      case "rejected":
        return "Rejected";
      case "failed":
        return "Failed";
      case "started":
        return "Started";
      case "pending":
        return "Pending";
      case "high":
        return "High";
      case "low":
        return "Low";
      case "medium":
        return "Medium";
      case "override":
        return "Override";
      case "Profile submitted to FIU":
        return "Medium";
      case "Profile submitted to FIU":
        return "Medium";
      case "override":
        return "Override";

      default:
        return "";
    }
  }
}
export const libraries = ["places"];
export const DoubleCheck = [
  {
    id: 1,
    todo: "Take Selfie/Photo",
    icon: <CAMERAICON />,
  },
  {
    id: 2,
    todo: "Upload / Take photo of Document",
    route: "/verify-document-with-document",
    icon: <EARTHICON />,
  },
  {
    id: 3,
    todo: "Video Upload",
    icon: <CAMERAICON />,
  },
  {
    id: 4,
    todo: "Verify Email",
    icon: <EMIALICONS />,
  },
  {
    id: 5,
    todo: "Verify Phone",
    icon: <PHONE />,
  },
  {
    id: 6,
    todo: "Feedback Message",
    icon: <FEEDBACK />,
  },

  {
    id: 7,
    todo: "Document Upload",
    icon: <DOCUMENT />,
  },
  {
    id: 8,
    todo: "Verify Selfi with Document",
    icon: <SELFIVERIFY />,
  },
  {
    id: 9,
    todo: "Custom Screen",
    icon: <SELFIVERIFY />,
  },
  {
    id: 10,
    todo: "Business Documents",
    icon: "DOCUMENT",
    icon: <DOCUMENT />,
  },
  {
    id: 11,
    todo: "Source of Funds Declaration",
    icon: <MONEY_ICON />,
    route: "/source-income",
  },
  {
    id: 12,
    todo: "Proof of Address Upload",
    icon: <DOCUMENT />,
  },

  {
    id: 13,

    todo: "Custom Screen",
    icon: <SELFIVERIFY />,
  },
  {
    id: 14,
    todo: "Custom Screen 2",
    icon: <SELFIVERIFY />,
  },
  {
    id: 15,

    todo: "Custom Screen 3",
    icon: <SELFIVERIFY />,
  },
  {
    id: 16,

    todo: "Custom Screen 4",
    icon: <SELFIVERIFY />,
  },
  {
    id: 17,

    todo: "Custom Screen 5",
    icon: <SELFIVERIFY />,
  },

  {
    id: 18,
    todo: "Capture Address",
    icon: <EARTHICON />,
  },

  {
    id: 19,
    todo: "Sanction Screen",
    icon: <EARTHICON />,
  },
  {
    id: 20,
    todo: "Verify Bank Account Item",
    icon: <EARTHICON />,
  },
  {
    id: 21,
    todo: "Verify ID with government data",
    icon: <EARTHICON />,
  },
  {
    id: 22,
    todo: "Verify Selfie with Government Selfie",
    icon: <EARTHICON />,
  },
  {
    id: 23,
    todo: "Country",
    icon: <EARTHICON />,
  },
  {
    id: 24,
    todo: "Liveliness",
    icon: <SELFIVERIFY />,
  },
  {
    id: 25,
    todo: "Liveliness/Selfie",
    icon: <SELFIVERIFY />,
  },
  {
    id: 26,
    todo: "Entity Capture",
    icon: <SELFIVERIFY />,
  },
  {
    id: 27,
    todo: "Custom Form",
    icon: <DOCUMENT />,
  },
];

export const DemoFlows = [
  {
    name: "Flow 1",
  },
  {
    name: "Flow 2",
  },
  {
    name: "Flow 3",
  },
  {
    name: "Flow 4",
  },
  {
    name: "Flow 5",
  },
];

export const DemoData = [
  {
    id: 1,
    todo: "Take Selfie/Photo",
    icon: "CAMERAICON",
    route: "/upload-selfie",
  },
  {
    id: 2,
    todo: "Upload / Take photo of Document",
    icon: "EARTHICON",
    route: "/upload-document",
  },
  {
    id: 3,
    todo: "Video Upload",
    icon: "CAMERAICON",
    route: "/upload-video-liveliness",
  },
  {
    id: 4,
    todo: "Verify Email",
    icon: "EMIALICONS",
    route: "/email-verification",
  },
  {
    id: 5,
    todo: "Verify Phone",
    icon: "PHONE",
    route: "/cell-verification",
  },
  {
    id: 6,
    todo: "Feedback Message",
    icon: "FEEDBACK",
    route: "/feedback-message",
  },

  {
    id: 7,
    todo: "Document Upload",

    icon: "DOCUMENT",
    route: "/upload-address-document",
  },
  {
    id: 8,
    todo: "Verify Selfi with Document",
    icon: "SELFIVERIFY",
    route: "/verify-document-with-document",
  },
  {
    id: 9,
    todo: "Custom Screen",
    icon: "SELFIVERIFY",
    route: "/custom-screen",
  },
  {
    id: 10,
    todo: "Business Documents",

    icon: "DOCUMENT",
    route: "/upload-address-document-2",
  },
  {
    id: 11,
    todo: "Source of Funds Declaration",
    icon: "PHONE",
    route: "/source-income",
  },
  {
    id: 12,
    todo: "Proof of Address Upload",
    icon: "PHONE",
    route: "/address-proof",
  },
  {
    id: 13,

    todo: "Custom Screen",
    icon: "FEEDBACK",
    route: "/custom-screen",
  },
  {
    id: 14,
    todo: "Custom Screen 2",
    icon: "FEEDBACK",
    route: "/custom-screen-2",
  },
  {
    id: 15,

    todo: "Custom Screen 3",
    icon: "FEEDBACK",
    route: "/custom-screen-3",
  },
  {
    id: 16,
    todo: "Custom Screen 4",
    icon: "FEEDBACK",
    route: "/custom-screen-4",
  },
  {
    id: 17,

    todo: "Custom Screen 5",
    icon: "FEEDBACK",
    route: "/custom-screen-5",
  },
  {
    id: 18,

    todo: "Capture Address",
    icon: "EARTHICON",
    route: "/capture-address",
  },

  {
    id: 19,
    todo: "Sanction Screen",
    icon: "PHONE",
    route: "/cell-verification",
  },
  {
    id: 20,
    todo: "Verify Bank Account Item",
    icon: "PHONE",
    route: "/cell-verification",
  },
  {
    id: 21,
    todo: "Verify ID with government data",
    icon: "PHONE",
    route: "/cell-verification",
  },
  {
    id: 22,
    todo: "Verify Selfie with Government Selfie",
    icon: "PHONE",
    route: "/cell-verification",
  },
  {
    id: 23,
    todo: "Country",
    icon: "PHONE",
    route: "/country-selecter",
  },
  {
    id: 24,
    todo: "Liveliness",
    icon: "CAMERAICON",
    route: "/livliness-video",
  },
  {
    id: 25,
    todo: "Liveliness/Selfie",
    icon: "CAMERAICON",
    route: "/livliness-video-selfie",
  },
  {
    id: 26,
    todo: "Entity Capture",
    icon: "CAMERAICON",
    route: "/entity-capture",
  },

  {
    id: 27,
    todo: "Custom Form",
    icon: "DOCUMENT",
    route: "/custom-form",
  },
];

export const ITS_MEAN = {
  name: "Scantion Screen Name",
  idNumber: "Id Number",
};
export const GIVEICONS = (ICONNAME) => {
  if (ICONNAME) {
    let getIcon = DoubleCheck.filter((data) =>
      // data.todo == ICONNAME || data.route == ICONNAME ? data?.icon : null
      data.todo == ICONNAME ? data?.icon : null
    );
    return getIcon[0].icon !== undefined ? getIcon[0].icon : "";
  }
};

export function ExtractID(data, setter) {
  let ids = [];
  if (data && Array.isArray(data)) {
    data.forEach((item) => {
      if (item && item._id) {
        ids.push(item._id);
      }
    });
  }
  setter(ids);
}
export const Timeline_ReTerm = {
  liveliness: "Liveliness",
  govIds: "Governement Id",
  selfie: "Selfie",
  fundSource: "Fund Source",
  addressIds: "Address Documents",
  "Address Verified": "Manually verified proof of address ",
  "Company Sanction": "Company Sanction Screen",
  "Check Id Address": "Verified address with credit bureau",
  "Bank Account": "Bank Account Verified ",
  "Fund Source": "Source Of funds declared ",
  "Check Id": "Identity number checked",
  Sanction: "Sanction /PEP Screen",
  "Address Proof": "Address manually approved",
  Status: "Final Status",
};

export function getFinalStatus(result) {
  if (result === "passed" || result === "approved") {
    return (
      <ToolTipWrapper
        Component={
          <CheckCircleOutlinedIcon
            style={{
              // background: "rgb(56, 174, 224)",
              color: "rgba(101, 183, 65, 1)",
              height: "30px",
              width: "30px",
              borderRadius: "100px",
              // border: "1px solid rgba(101, 183, 65, 1)",
              // margin: "0 5px",
            }}
          />
        }
        PlaceHolder={"PASSED"}
        // handleFunc={() => set_Open_ExtractOption(true)}
        handleFunc={() => ""}
      />
    );
  } else if (result === "failed" || result === "rejected") {
    return (
      <ToolTipWrapper
        Component={
          <ErrorOutlineIcon
            style={{
              // background: "rgb(56, 174, 224)",
              color: "rgba(247, 37, 47, 1)",
              height: "30px",
              width: "30px",
              borderRadius: "100px",
            }}
          />
        }
        PlaceHolder={"FAILED"}
        // handleFunc={() => set_Open_ExtractOption(true)}
        handleFunc={() => ""}
      />
    );
  } else if (result === "pending") {
    return (
      <ToolTipWrapper
        Component={
          <ErrorOutlineIcon
            style={{
              // background: "rgb(56, 174, 224)",
              color: "rgb(199, 199, 131)",
              height: "30px",
              width: "30px",
              borderRadius: "100px",
              // border: "1px solid rgb(199, 199, 131)",
              // padding: "5px",
            }}
          />
        }
        PlaceHolder={"PENDING"}
        // handleFunc={() => set_Open_ExtractOption(true)}
        handleFunc={() => ""}
      />
    );
  } else if (result === "override") {
    return (
      <ToolTipWrapper
        Component={
          <CheckCircleOutlinedIcon
            style={{
              // background: "rgb(56, 174, 224)",
              color: "orange",
              height: "30px",
              width: "30px",
              borderRadius: "100px",
              border: "orange",
              // padding: "5px",
            }}
          />
        }
        PlaceHolder={"Override"}
        // handleFunc={() => set_Open_ExtractOption(true)}
        handleFunc={() => ""}
      />
    );
  } else if (result === "started") {
    return (
      <ToolTipWrapper
        Component={
          <CheckCircleOutlinedIcon
            style={{
              // background: "rgb(56, 174, 224)",
              color: "rgba(101, 183, 65, 1)",
              height: "30px",
              width: "30px",
              borderRadius: "100px",
              border: "1px solid rgba(101, 183, 65, 1)",
              padding: "5px",
            }}
          />
        }
        PlaceHolder={"STARTED"}
        // handleFunc={() => set_Open_ExtractOption(true)}
        handleFunc={() => ""}
      />
    );
  } else {
    return "";
  }
}

const SCORE = ({ value, color }) => (
  <div
    style={{
      color: color,
      borderRadius: "100px",
      width: "15px",
      height: "15px",
      fontSize: "12px",
      fontWeight: "700",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      border: `1px solid ${color}`,
      alignItems: "center",
      padding: "15px",
    }}
  >
    {value}
  </div>
);

export function getColorCode(value, status) {
  if (status == "pending") {
    return <SCORE value={value} color={"rgb(210 210 111)"} />;
  } else if (status == "failed") {
    return <SCORE value={value} color={"red"} />;
  } else if (status == "passed") {
    return <SCORE value={value} color={"lightgreen"} />;
  } else if (status == "override") {
    return <SCORE value={value} color={"orange"} />;
  } else if (value >= 1) {
    return <SCORE value={value} color={"lightgreen"} />;
  } else if (value == 0.5) {
    return <SCORE value={value} color={"orange"} />;
  } else if (value <= -1) {
    return <SCORE value={value} color={"red"} />;
  } else {
    return <SCORE value={value} color={"lightgrey"} />;
  }
}
export function getStatus_Total_Score(status) {
  if (status == "high") {
    return "Fld";
  } else if (status == "low") {
    return "Pss";
  } else if (status == "medium") {
    return "Pnd";
  }
}

export function processData(data) {
  return data !== null && data !== undefined ? data : "No data Available";
}

export const PageName = {
  "/workflow-management": "Workflow Management",
  "/view-workflow": "View Workflow",
  "/entity-management": "Entity Management",
  "/dashboard": "Dashboard",
  "/individual-management": "Individual Management",
  "/team-management": "Team Management",
  "/roles-management": "Role Management",
  "/risk-matrix": "Risk Matrix",
  "/workflow-management": "Workflow Management",
  "/invoice": "Invoice",
  "/credit-invoice": "Credit Invoice",
  "/profile-settings": "Profile Settings",
  "/developer-options": "Developer Options",
  "/fiu": "FIU",
  "/data-retention": "Data Retention",
  "/subscription": "Subscriptions",
  "/subscription-plans": "Subscription Plans",
  "/subscription-payment": "Payment",
  "/add-individual": "Add Individual",
  "/add-roles": "Add Roles",
  "/edit-roles": "Update Roles",
  "/workflow-Edit": "Update Workflow",
  "/add-workflow": "Add Workflow",
  "/individual-verification-detail": "Individual Details",
  "/add-entity": "Add Entity",
  "/update-entity": "Update Entity",
  "/view-roles": "View Roles",
  "/entity-details": "Entity Details",
  "/notifications": "Notifications",
  "/email-template/update-template": "Update Template",
  "/email-template/add-template": "Add Template",
  "/email-template/view-template": "View Template",
  "/schedule": "Schedule",
  "/webhooks-management": "Webhook Management",
  "/webhooks-logs": "Webhook logs",
  "/add-webhook": "Add Webhook",
  "/webhooks/update": "Update Webhook",
  "/webhooks/view": "View Webhook",
  "/individual-risk-matrix": "Individual Risk Matrix",
  "/entity-risk-matrix": "Entity Risk Matrix",
  "/credit-logs": "Credit Logs",
  "/creadit-payment": "Buy Credit",
  "/email-template-management": "Email Template Management",
  "/add-individual-equity": "Add Individual",
  "/login-history": "Login History",
  "/fiu-logs": "FIU logs",
  "/organogram": "Organogram",
  "/add-entity-equity": "Add Entity",
  "/sendgrid": "Sendgrid",
  "/report": "Report",
  "/report-management": "Report Management",
};
export const Templates_All = [
  {
    Template: `Welcome to Glorep! We're excited to have you on board. By verifying your email, you'll have full access to your Glorep account and all its features.

To verify your email address, please click the link below:

If you did not sign up for an account, please disregard this email.

Thank you for choosing Glorep!
`,
  },
  {
    Template: `Dear Valued Users,

We hope this message finds you well. We have an exciting offer for you, but time is running out!

[Plan/Product] at [Company Name] is currently available at a special discounted rate, but this offer ends soon. Don't miss out on the chance to [mention the benefits of the plan/product].

**Offer Details:**
- Plan/Product: [Plan/Product Name]
- Discounted Price: $[Discounted Price]
- Offer Ends: [End Date] (Sunday)

This is a limited-time opportunity to [highlight the value or advantages of the plan/product]. To take advantage of this offer, simply [provide instructions on how to make the purchase].

If you have any questions or need assistance with your purchase, please don't hesitate to reach out to our customer support team at [Customer Support Email or Phone Number].

We highly recommend acting quickly to secure this special offer before it expires on [End Date]. Thank you for being a valued member of the [Company Name] community, and we look forward to serving you.

Best regards,
[Your Name]
[Your Title/Administrator]
[Company Name]
[Contact Information]`,
  },
  {
    Template: `Dear [User's Name],

We hope this message finds you well. We wanted to let you know that your plan subscription with [Company Name] has expired. We appreciate your continued support and want to ensure you continue to enjoy the benefits of our services.

**Plan Details:**
- Plan Name: [Plan Name]
- Expiration Date: [Expiration Date]

To renew your plan and regain access to our premium features, please follow these simple steps:

1. Visit our website at [Website URL].
2. Log in to your account using your username and password.
3. Navigate to the "Subscription" or "Account" section.
4. Select the "Renew Plan" option.
5. Choose your preferred plan and payment method.
6. Confirm your payment details and complete the renewal process.

Renewing your plan is quick and easy, and it ensures uninterrupted access to all the features and benefits you've come to rely on.

If you have any questions or encounter any issues during the renewal process, please don't hesitate to contact our customer support team at [Customer Support Email or Phone Number]. We're here to assist you every step of the way.

Thank you for choosing [Company Name]. We value your business and look forward to continuing to serve you.

Best regards,
[Your Name]
[Your Title/Administrator]
[Company Name]
[Contact Information]`,
  },

  {
    Template: `Dear [User's Name],

We hope this message finds you well. We regret to inform you that your account with [Company Name] has been temporarily suspended due to detected suspicious activity. Your security is our top priority, and we take these measures to protect your account and data.

**Suspension Details:**
- Account ID: [Account ID]
- Suspension Date: [Suspension Date]
- Reason: Suspicious Activity

To resolve this matter and reinstate your account, we kindly request that you take the following steps:

1. Contact our customer support team immediately at [Customer Support Email or Phone Number]. Our dedicated team will assist you in verifying your identity and lifting the suspension.
2. Be prepared to provide any necessary information or documentation to help us confirm your identity and address the security concern.

Once we have verified your identity and confirmed that your account is secure, we will promptly lift the suspension, and you will regain access to your account and all its features.

We understand that security measures like these can be inconvenient, but they are crucial to maintaining the integrity and safety of our platform. We appreciate your understanding and cooperation.

If you have any questions or concerns, please do not hesitate to reach out to our customer support team. We are here to assist you every step of the way.

Thank you for your prompt attention to this matter. We look forward to resolving the situation and welcoming you back as a valued member of [Company Name].

Best regards,
[Your Name]
[Your Title/Administrator]
[Company Name]
[Contact Information]`,
  },
  {
    Template: `

Dear [User's Name],

Welcome to [Your Company Name]! We're thrilled to have you on board and are excited to have you as part of our community.

Here's what you can expect as a [Your Company Name] member:

- Access to our [List Key Features or Services].
- Exclusive content and updates tailored just for you.
- Opportunities to connect with like-minded individuals.

To get started, here are a few steps you can take:

1. Explore our platform and familiarize yourself with all the features.
2. Customize your profile and settings to make the most of your experience.
3. Connect with other members and start engaging in discussions or activities.

If you have any questions or need assistance, our support team is here to help. Feel free to reach out to us at [Customer Support Email or Phone Number].

Thank you for choosing [Your Company Name]. We're committed to providing you with an exceptional experience, and we look forward to serving you.

Best regards,
[Your Name]
[Your Title/Administrator]
[Your Company Name]
[Contact Information]`,
  },

  {
    Template: "",
  },
];
export function isValidUrl(url) {
  // Regular expression for URL validation
  // const urlRegex =
  //   /^(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})(?:\/[\w-]+)*(?:\/[\w\-]+(?:\.[\w\-]+)?)?$/;

  // return urlRegex.test(url);
  return isUrl(url);
}

export const Include = [
  // "PrDPCode",
  // "PrDPermitExpiryDate",
  // "idNumberType",
  "names",
  "surname",

  "date_of_birth",
  "birthdate",
  "gender",
  "idCountryOfIssue",

  "licenseIssueDate",
  "licenseExpiryDate",
  "licenseNumber",
  "expiration_date",
  "nationality",
  "personal_number",
  "gender",
  "idNumber",
  "sex",
  "document_number",
  "licenseIssueDate",
  "licenseExpiryDate",
  "document_number",
  "country",
];

export const NO_DATA_AVAILABLE = ({ RowData }) =>
  RowData && <SpinnerLoader state={true} />;
export const KeyValueDisplay = ({
  keyName,
  value,
  alternativeKey,
  BirthDayScore,
}) => {
  // Check if the provided keyName is included in the array
  const isValidKey = Include.includes(keyName);

  // Use alternativeKey if keyName is not valid
  // const displayKey = isValidKey ? keyName : alternativeKey;
  const wanttoShow = {
    personal_number: "Personal Number",
    date_of_birth: "Date Of Birth",
    expiration_date: "Expiry Date",
    idNumber: "Personal Number",
    document_number: "Id Number",
    licenseNumber: "Document Number",
    idCountryOfIssue: "Country",
    sex: "Gender",
    names: "First Name",
    surname: "Last Name",
    birthdate: "Date Of Birth",
    licenseIssueDate: "Date Issued",
    licenseExpiryDate: "Expiry Date",
    document_number: "Document Number",
    surname: "Last Name",
    email: "Email",
    phone: "Phone",
  };
  let label = wanttoShow[keyName] || keyName;

  return (
    isValidKey && (
      <div className="A_1_">
        <div
          style={{
            display: "flex",
            gap: "5px",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <span className="T_1">
            {/* {wanttoShow[keyName] ? wanttoShow[keyName] : keyName} */}
            {label}
          </span>
          {label == "Date Of Birth" && BirthDayScore}
        </div>
        <span
          className="T_1 Scndry"
          style={{ display: "flex", alignItems: "center", gap: "10px" }}
        >
          {value}
        </span>
      </div>
    )
  );
};

export const findValueByKey = (param, obj) => {
  const matchingKey = Object.keys(obj).find(
    (key) => key.toLowerCase() === param.toLowerCase()
  );

  return matchingKey ? obj[matchingKey] : null;
};

export const Serial = [
  "First Name",
  "Last Name",
  "Date Of Birth",
  "Gender",
  "Personal Number",
  "Id Number",
  "Document Number",
  "Date Issued",
  "Expiry Date",
  "Country",
];

export function Custom_Slice_String(str, limit) {
  // Split the string into words
  if (str.length > limit) {
    return str.slice(0, limit) + " ...";
  } else {
    return str;
  }
}
export const countries = [
  { code: "AD", label: "Andorra", phone: "376" },
  {
    code: "AE",
    label: "United Arab Emirates",
    phone: "971",
  },
  { code: "AF", label: "Afghanistan", phone: "93" },
  {
    code: "AG",
    label: "Antigua and Barbuda",
    phone: "1-268",
  },
  { code: "AI", label: "Anguilla", phone: "1-264" },
  { code: "AL", label: "Albania", phone: "355" },
  { code: "AM", label: "Armenia", phone: "374" },
  { code: "AO", label: "Angola", phone: "244" },
  { code: "AQ", label: "Antarctica", phone: "672" },
  { code: "AR", label: "Argentina", phone: "54" },
  { code: "AS", label: "American Samoa", phone: "1-684" },
  { code: "AT", label: "Austria", phone: "43" },
  {
    code: "AU",
    label: "Australia",
    phone: "61",
    suggested: true,
  },
  { code: "AW", label: "Aruba", phone: "297" },
  { code: "AX", label: "Alland Islands", phone: "358" },
  { code: "AZ", label: "Azerbaijan", phone: "994" },
  {
    code: "BA",
    label: "Bosnia and Herzegovina",
    phone: "387",
  },
  { code: "BB", label: "Barbados", phone: "1-246" },
  { code: "BD", label: "Bangladesh", phone: "880" },
  { code: "BE", label: "Belgium", phone: "32" },
  { code: "BF", label: "Burkina Faso", phone: "226" },
  { code: "BG", label: "Bulgaria", phone: "359" },
  { code: "BH", label: "Bahrain", phone: "973" },
  { code: "BI", label: "Burundi", phone: "257" },
  { code: "BJ", label: "Benin", phone: "229" },
  { code: "BL", label: "Saint Barthelemy", phone: "590" },
  { code: "BM", label: "Bermuda", phone: "1-441" },
  { code: "BN", label: "Brunei Darussalam", phone: "673" },
  { code: "BO", label: "Bolivia", phone: "591" },
  { code: "BR", label: "Brazil", phone: "55" },
  { code: "BS", label: "Bahamas", phone: "1-242" },
  { code: "BT", label: "Bhutan", phone: "975" },
  { code: "BV", label: "Bouvet Island", phone: "47" },
  { code: "BW", label: "Botswana", phone: "267" },
  { code: "BY", label: "Belarus", phone: "375" },
  { code: "BZ", label: "Belize", phone: "501" },
  {
    code: "CA",
    label: "Canada",
    phone: "1",
    suggested: true,
  },
  {
    code: "CC",
    label: "Cocos (Keeling) Islands",
    phone: "61",
  },
  {
    code: "CD",
    label: "Congo, Democratic Republic of the",
    phone: "243",
  },
  {
    code: "CF",
    label: "Central African Republic",
    phone: "236",
  },
  {
    code: "CG",
    label: "Congo, Republic of the",
    phone: "242",
  },
  { code: "CH", label: "Switzerland", phone: "41" },
  { code: "CI", label: "Cote d'Ivoire", phone: "225" },
  { code: "CK", label: "Cook Islands", phone: "682" },
  { code: "CL", label: "Chile", phone: "56" },
  { code: "CM", label: "Cameroon", phone: "237" },
  { code: "CN", label: "China", phone: "86" },
  { code: "CO", label: "Colombia", phone: "57" },
  { code: "CR", label: "Costa Rica", phone: "506" },
  { code: "CU", label: "Cuba", phone: "53" },
  { code: "CV", label: "Cape Verde", phone: "238" },
  { code: "CW", label: "Curacao", phone: "599" },
  { code: "CX", label: "Christmas Island", phone: "61" },
  { code: "CY", label: "Cyprus", phone: "357" },
  { code: "CZ", label: "Czech Republic", phone: "420" },
  {
    code: "DE",
    label: "Germany",
    phone: "49",
    suggested: true,
  },
  { code: "DJ", label: "Djibouti", phone: "253" },
  { code: "DK", label: "Denmark", phone: "45" },
  { code: "DM", label: "Dominica", phone: "1-767" },
  {
    code: "DO",
    label: "Dominican Republic",
    phone: "1-809",
  },
  { code: "DZ", label: "Algeria", phone: "213" },
  { code: "EC", label: "Ecuador", phone: "593" },
  { code: "EE", label: "Estonia", phone: "372" },
  { code: "EG", label: "Egypt", phone: "20" },
  { code: "EH", label: "Western Sahara", phone: "212" },
  { code: "ER", label: "Eritrea", phone: "291" },
  { code: "ES", label: "Spain", phone: "34" },
  { code: "ET", label: "Ethiopia", phone: "251" },
  { code: "FI", label: "Finland", phone: "358" },
  { code: "FJ", label: "Fiji", phone: "679" },
  {
    code: "FK",
    label: "Falkland Islands (Malvinas)",
    phone: "500",
  },
  {
    code: "FM",
    label: "Micronesia, Federated States of",
    phone: "691",
  },
  { code: "FO", label: "Faroe Islands", phone: "298" },
  {
    code: "FR",
    label: "France",
    phone: "33",
    suggested: true,
  },
  { code: "GA", label: "Gabon", phone: "241" },
  { code: "GB", label: "United Kingdom", phone: "44" },
  { code: "GD", label: "Grenada", phone: "1-473" },
  { code: "GE", label: "Georgia", phone: "995" },
  { code: "GF", label: "French Guiana", phone: "594" },
  { code: "GG", label: "Guernsey", phone: "44" },
  { code: "GH", label: "Ghana", phone: "233" },
  { code: "GI", label: "Gibraltar", phone: "350" },
  { code: "GL", label: "Greenland", phone: "299" },
  { code: "GM", label: "Gambia", phone: "220" },
  { code: "GN", label: "Guinea", phone: "224" },
  { code: "GP", label: "Guadeloupe", phone: "590" },
  { code: "GQ", label: "Equatorial Guinea", phone: "240" },
  { code: "GR", label: "Greece", phone: "30" },
  {
    code: "GS",
    label: "South Georgia and the South Sandwich Islands",
    phone: "500",
  },
  { code: "GT", label: "Guatemala", phone: "502" },
  { code: "GU", label: "Guam", phone: "1-671" },
  { code: "GW", label: "Guinea-Bissau", phone: "245" },
  { code: "GY", label: "Guyana", phone: "592" },
  { code: "HK", label: "Hong Kong", phone: "852" },
  {
    code: "HM",
    label: "Heard Island and McDonald Islands",
    phone: "672",
  },
  { code: "HN", label: "Honduras", phone: "504" },
  { code: "HR", label: "Croatia", phone: "385" },
  { code: "HT", label: "Haiti", phone: "509" },
  { code: "HU", label: "Hungary", phone: "36" },
  { code: "ID", label: "Indonesia", phone: "62" },
  { code: "IE", label: "Ireland", phone: "353" },
  { code: "IL", label: "Israel", phone: "972" },
  { code: "IM", label: "Isle of Man", phone: "44" },
  { code: "IN", label: "India", phone: "91" },
  {
    code: "IO",
    label: "British Indian Ocean Territory",
    phone: "246",
  },
  { code: "IQ", label: "Iraq", phone: "964" },
  {
    code: "IR",
    label: "Iran, Islamic Republic of",
    phone: "98",
  },
  { code: "IS", label: "Iceland", phone: "354" },
  { code: "IT", label: "Italy", phone: "39" },
  { code: "JE", label: "Jersey", phone: "44" },
  { code: "JM", label: "Jamaica", phone: "1-876" },
  { code: "JO", label: "Jordan", phone: "962" },
  {
    code: "JP",
    label: "Japan",
    phone: "81",
    suggested: true,
  },
  { code: "KE", label: "Kenya", phone: "254" },
  { code: "KG", label: "Kyrgyzstan", phone: "996" },
  { code: "KH", label: "Cambodia", phone: "855" },
  { code: "KI", label: "Kiribati", phone: "686" },
  { code: "KM", label: "Comoros", phone: "269" },
  {
    code: "KN",
    label: "Saint Kitts and Nevis",
    phone: "1-869",
  },
  {
    code: "KP",
    label: "Korea, Democratic People's Republic of",
    phone: "850",
  },
  { code: "KR", label: "Korea, Republic of", phone: "82" },
  { code: "KW", label: "Kuwait", phone: "965" },
  { code: "KY", label: "Cayman Islands", phone: "1-345" },
  { code: "KZ", label: "Kazakhstan", phone: "7" },
  {
    code: "LA",
    label: "Lao People's Democratic Republic",
    phone: "856",
  },
  { code: "LB", label: "Lebanon", phone: "961" },
  { code: "LC", label: "Saint Lucia", phone: "1-758" },
  { code: "LI", label: "Liechtenstein", phone: "423" },
  { code: "LK", label: "Sri Lanka", phone: "94" },
  { code: "LR", label: "Liberia", phone: "231" },
  { code: "LS", label: "Lesotho", phone: "266" },
  { code: "LT", label: "Lithuania", phone: "370" },
  { code: "LU", label: "Luxembourg", phone: "352" },
  { code: "LV", label: "Latvia", phone: "371" },
  { code: "LY", label: "Libya", phone: "218" },
  { code: "MA", label: "Morocco", phone: "212" },
  { code: "MC", label: "Monaco", phone: "377" },
  {
    code: "MD",
    label: "Moldova, Republic of",
    phone: "373",
  },
  { code: "ME", label: "Montenegro", phone: "382" },
  {
    code: "MF",
    label: "Saint Martin (French part)",
    phone: "590",
  },
  { code: "MG", label: "Madagascar", phone: "261" },
  { code: "MH", label: "Marshall Islands", phone: "692" },
  {
    code: "MK",
    label: "Macedonia, the Former Yugoslav Republic of",
    phone: "389",
  },
  { code: "ML", label: "Mali", phone: "223" },
  { code: "MM", label: "Myanmar", phone: "95" },
  { code: "MN", label: "Mongolia", phone: "976" },
  { code: "MO", label: "Macao", phone: "853" },
  {
    code: "MP",
    label: "Northern Mariana Islands",
    phone: "1-670",
  },
  { code: "MQ", label: "Martinique", phone: "596" },
  { code: "MR", label: "Mauritania", phone: "222" },
  { code: "MS", label: "Montserrat", phone: "1-664" },
  { code: "MT", label: "Malta", phone: "356" },
  { code: "MU", label: "Mauritius", phone: "230" },
  { code: "MV", label: "Maldives", phone: "960" },
  { code: "MW", label: "Malawi", phone: "265" },
  { code: "MX", label: "Mexico", phone: "52" },
  { code: "MY", label: "Malaysia", phone: "60" },
  { code: "MZ", label: "Mozambique", phone: "258" },
  { code: "NA", label: "Namibia", phone: "264" },
  { code: "NC", label: "New Caledonia", phone: "687" },
  { code: "NE", label: "Niger", phone: "227" },
  { code: "NF", label: "Norfolk Island", phone: "672" },
  { code: "NG", label: "Nigeria", phone: "234" },
  { code: "NI", label: "Nicaragua", phone: "505" },
  { code: "NL", label: "Netherlands", phone: "31" },
  { code: "NO", label: "Norway", phone: "47" },
  { code: "NP", label: "Nepal", phone: "977" },
  { code: "NR", label: "Nauru", phone: "674" },
  { code: "NU", label: "Niue", phone: "683" },
  { code: "NZ", label: "New Zealand", phone: "64" },
  { code: "OM", label: "Oman", phone: "968" },
  { code: "PA", label: "Panama", phone: "507" },
  { code: "PE", label: "Peru", phone: "51" },
  { code: "PF", label: "French Polynesia", phone: "689" },
  { code: "PG", label: "Papua New Guinea", phone: "675" },
  { code: "PH", label: "Philippines", phone: "63" },
  { code: "PK", label: "Pakistan", phone: "92" },
  { code: "PL", label: "Poland", phone: "48" },
  {
    code: "PM",
    label: "Saint Pierre and Miquelon",
    phone: "508",
  },
  { code: "PN", label: "Pitcairn", phone: "870" },
  { code: "PR", label: "Puerto Rico", phone: "1" },
  {
    code: "PS",
    label: "Palestine, State of",
    phone: "970",
  },
  { code: "PT", label: "Portugal", phone: "351" },
  { code: "PW", label: "Palau", phone: "680" },
  { code: "PY", label: "Paraguay", phone: "595" },
  { code: "QA", label: "Qatar", phone: "974" },
  { code: "RE", label: "Reunion", phone: "262" },
  { code: "RO", label: "Romania", phone: "40" },
  { code: "RS", label: "Serbia", phone: "381" },
  { code: "RU", label: "Russian Federation", phone: "7" },
  { code: "RW", label: "Rwanda", phone: "250" },
  { code: "SA", label: "Saudi Arabia", phone: "966" },
  { code: "SB", label: "Solomon Islands", phone: "677" },
  { code: "SC", label: "Seychelles", phone: "248" },
  { code: "SD", label: "Sudan", phone: "249" },
  { code: "SE", label: "Sweden", phone: "46" },
  { code: "SG", label: "Singapore", phone: "65" },
  { code: "SH", label: "Saint Helena", phone: "290" },
  { code: "SI", label: "Slovenia", phone: "386" },
  {
    code: "SJ",
    label: "Svalbard and Jan Mayen",
    phone: "47",
  },
  { code: "SK", label: "Slovakia", phone: "421" },
  { code: "SL", label: "Sierra Leone", phone: "232" },
  { code: "SM", label: "San Marino", phone: "378" },
  { code: "SN", label: "Senegal", phone: "221" },
  { code: "SO", label: "Somalia", phone: "252" },
  { code: "SR", label: "Suriname", phone: "597" },
  { code: "SS", label: "South Sudan", phone: "211" },
  {
    code: "ST",
    label: "Sao Tome and Principe",
    phone: "239",
  },
  { code: "SV", label: "El Salvador", phone: "503" },
  {
    code: "SX",
    label: "Sint Maarten (Dutch part)",
    phone: "1-721",
  },
  {
    code: "SY",
    label: "Syrian Arab Republic",
    phone: "963",
  },
  { code: "SZ", label: "Swaziland", phone: "268" },
  {
    code: "TC",
    label: "Turks and Caicos Islands",
    phone: "1-649",
  },
  { code: "TD", label: "Chad", phone: "235" },
  {
    code: "TF",
    label: "French Southern Territories",
    phone: "262",
  },
  { code: "TG", label: "Togo", phone: "228" },
  { code: "TH", label: "Thailand", phone: "66" },
  { code: "TJ", label: "Tajikistan", phone: "992" },
  { code: "TK", label: "Tokelau", phone: "690" },
  { code: "TL", label: "Timor-Leste", phone: "670" },
  { code: "TM", label: "Turkmenistan", phone: "993" },
  { code: "TN", label: "Tunisia", phone: "216" },
  { code: "TO", label: "Tonga", phone: "676" },
  { code: "TR", label: "Turkey", phone: "90" },
  {
    code: "TT",
    label: "Trinidad and Tobago",
    phone: "1-868",
  },
  { code: "TV", label: "Tuvalu", phone: "688" },
  {
    code: "TW",
    label: "Taiwan, Republic of China",
    phone: "886",
  },
  {
    code: "TZ",
    label: "United Republic of Tanzania",
    phone: "255",
  },
  { code: "UA", label: "Ukraine", phone: "380" },
  { code: "UG", label: "Uganda", phone: "256" },
  {
    code: "US",
    label: "United States",
    phone: "1",
    suggested: true,
  },
  { code: "UY", label: "Uruguay", phone: "598" },
  { code: "UZ", label: "Uzbekistan", phone: "998" },
  {
    code: "VA",
    label: "Holy See (Vatican City State)",
    phone: "379",
  },
  {
    code: "VC",
    label: "Saint Vincent and the Grenadines",
    phone: "1-784",
  },
  { code: "VE", label: "Venezuela", phone: "58" },
  {
    code: "VG",
    label: "British Virgin Islands",
    phone: "1-284",
  },
  {
    code: "VI",
    label: "US Virgin Islands",
    phone: "1-340",
  },
  { code: "VN", label: "Vietnam", phone: "84" },
  { code: "VU", label: "Vanuatu", phone: "678" },
  { code: "WF", label: "Wallis and Futuna", phone: "681" },
  { code: "WS", label: "Samoa", phone: "685" },
  { code: "XK", label: "Kosovo", phone: "383" },
  { code: "YE", label: "Yemen", phone: "967" },
  { code: "YT", label: "Mayotte", phone: "262" },
  { code: "ZA", label: "South Africa", phone: "27" },
  { code: "ZM", label: "Zambia", phone: "260" },
  { code: "ZW", label: "Zimbabwe", phone: "263" },
];

export const findObjectByLabel = (labelToFind) => {
  return countries.find((obj) => obj.label === labelToFind);
};

export const ItMean = {
  other: "Others Id",
  passport: "Passport",
  governmentId: "Government Id",
  driverLicence: "Driver License",
  greenCard: "South African Green ID Book",
};
export const currentDate = () => moment().format("YYYY-MM-DD");
export const firstDay = (date) => {
  const currentDate = moment();

  let day = currentDate.clone().startOf(date);
  return day.format("YYYY-MM-DD hh:mm:ss");
};
export const lastDay = (date) => {
  const currentDate = moment();

  let day = currentDate.clone().endOf(date);
  return day.format("YYYY-MM-DD");
};
export const give_Month = (date) => {
  const formate = moment(date);
  return formate.format("MMMM");
};
export const Take_Date = (month, day) => {
  const year = moment().year();
  const date = moment(`${year}-${month}-${day}`, "YYYY-MM-DD");
  return date.format("YYYY-MM-DD");
};
export const Hour = [
  { label: 0, value: 0 },
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 9, value: 9 },
  { label: 10, value: 10 },
  { label: 11, value: 11 },
  { label: 12, value: 12 },
  { label: 13, value: 13 },
  { label: 14, value: 14 },
  { label: 15, value: 15 },
  { label: 16, value: 16 },
  { label: 17, value: 17 },
  { label: 18, value: 18 },
  { label: 19, value: 19 },
  { label: 20, value: 20 },
  { label: 21, value: 21 },
  { label: 22, value: 22 },
  { label: 23, value: 23 },
];
export const Entity_Type = [
  { label: "Sole Proprietorship", value: "Sole Proprietorship" },
  { label: "General Partnership", value: "General Partnership" },
  {
    label: "Limited Partnership (LP)",
    value: "Limited Partnership (LP)",
  },
  { label: "Corporation", value: "Corporation" },
  {
    label: "Limited Liability Company (LLC)",
    value: "Limited Liability Company (LLC)",
  },
  {
    label: "Nonprofit Organization (NPO)",
    value: "Nonprofit Organization (NPO)",
  },
  { label: "Cooperative (Co-op)", value: "Cooperative (Co-op)" },
  {
    label: "non-governmental organization (NGO)",
    value: "non-governmental organization (NGO)",
  },
  { label: "Trust", value: "Trust" },
  { label: "Business Trust", value: "Business Trust" },
  { label: "Other", value: "Other" },
];
export function convertToNumber(str) {
  // Attempt to convert the string to a number
  const num = parseFloat(str);

  // Check if the conversion resulted in NaN (Not a Number)
  if (isNaN(num)) {
    return 0; // Return 0 if the input is not a valid number
  } else {
    return num; // Return the converted number if successful
  }
}

export function giveCustomName(array) {
  const change = {
    individualsAtHighRisk: "High",
    individualsAtLowRisk: "Low",
    individualsAtMediumRisk: "Medium",

    failedIndividuals: "Failed",
    passedIndividuals: "Passed",
    pendingIndividuals: "Pending",
    startedIndividuals: "Started",
    // totalIndividuals: "Total Individuals",

    failedEntities: "Failed",
    passedEntities: "Passed",
    pendingEntities: "Pending",

    startedEntities: "Started",

    // totalEntities: "Total Entity",

    entitiesAtHighRisk: "High",
    entitiesAtLowRisk: "Low",
    entitiesAtMediumRisk: "Medium",
  };
  const temp = [];

  if (Array.isArray(array))
    array.forEach((data) => {
      if (change[data] !== undefined) {
        temp.push(change[data]);
      }
    });
  return temp;
}
export const Minute = [
  { label: 0, value: 0 },
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 9, value: 9 },
  { label: 10, value: 10 },
  { label: 11, value: 11 },
  { label: 12, value: 12 },
  { label: 13, value: 13 },
  { label: 14, value: 14 },
  { label: 15, value: 15 },
  { label: 16, value: 16 },
  { label: 17, value: 17 },
  { label: 18, value: 18 },
  { label: 19, value: 19 },
  { label: 20, value: 20 },
  { label: 21, value: 21 },
  { label: 22, value: 22 },
  { label: 23, value: 23 },
  { label: 24, value: 24 },
  { label: 25, value: 25 },
  { label: 26, value: 26 },
  { label: 27, value: 27 },
  { label: 28, value: 28 },
  { label: 29, value: 29 },
  { label: 30, value: 30 },
  { label: 31, value: 31 },
  { label: 32, value: 32 },
  { label: 33, value: 33 },
  { label: 34, value: 34 },
  { label: 35, value: 35 },
  { label: 36, value: 36 },
  { label: 37, value: 37 },
  { label: 38, value: 38 },
  { label: 39, value: 39 },
  { label: 40, value: 40 },
  { label: 41, value: 41 },
  { label: 42, value: 42 },
  { label: 43, value: 43 },
  { label: 44, value: 44 },
  { label: 45, value: 45 },
  { label: 46, value: 46 },
  { label: 47, value: 47 },
  { label: 48, value: 48 },
  { label: 49, value: 49 },
  { label: 50, value: 50 },
  { label: 51, value: 51 },
  { label: 52, value: 52 },
  { label: 53, value: 53 },
  { label: 54, value: 54 },
  { label: 55, value: 55 },
  { label: 56, value: 56 },
  { label: 57, value: 57 },
  { label: 58, value: 58 },
  { label: 59, value: 59 },
];
export const Weekdays = [
  { label: "Monday", value: "monday" },
  { label: "Tuesday", value: "tuesday" },
  { label: "Wednesday", value: "wednesday" },
  { label: "Thursday", value: "thursday" },
  { label: "Friday", value: "friday" },
  { label: "Saturday", value: "saturday" },
  { label: "Sunday", value: "sunday" },
];
export const all_Departments = [
  { value: "Accountant", label: "Accountant" },
  { value: "Alternate Director", label: "Alternate Director" },
  { value: "Audit Committee Member", label: "Audit Committee Member" },
  { value: "Auditor", label: "Auditor" },
  { value: "Board Member", label: "Board Member" },
  { value: "Both Director/Officer", label: "Both Director/Officer" },
  { value: "Chairman Of The Board", label: "Chairman Of The Board" },
  { value: "Chief Executive Officer", label: "Chief Executive Officer" },
  { value: "Chief Operation Officer", label: "Chief Operation Officer" },
  {
    value: "Company Secretary (Natural Person)",
    label: "Company Secretary (Natural Person)",
  },
  { value: "Compliance Officer", label: "Compliance Officer" },
  { value: "Co-Owner", label: "Co-Owner" },
  {
    value: "Director (Companies And CC)",
    label: "Director (Companies And CC)",
  },
  { value: "Employee", label: "Employee" },
  { value: "Executor", label: "Executor" },
  { value: "External / Overseas", label: "External / Overseas" },
  { value: "Financial Director", label: "Financial Director" },
  { value: "Founding Member", label: "Founding Member" },
  { value: "Incorporator", label: "Incorporator" },
  { value: "Joint Executor", label: "Joint Executor" },
  { value: "Legal Representative", label: "Legal Representative" },
  { value: "Local Managers", label: "Local Managers" },
  { value: "Manager", label: "Manager" },
  { value: "Member", label: "Member" },
  {
    value: "Money Laundering Reporting Officer",
    label: "Money Laundering Reporting Officer",
  },
  { value: "Non-Executive Director", label: "Non-Executive Director" },
  {
    value: "Non-Executive Independent Director",
    label: "Non-Executive Independent Director",
  },
  { value: "Officer", label: "Officer" },
  { value: "Other Trustee", label: "Other Trustee" },
  { value: "Owner", label: "Owner" },
  { value: "Partnership", label: "Partnership" },
  {
    value: "Primary/Secondary Founding Member",
    label: "Primary/Secondary Founding Member",
  },
  { value: "Representative", label: "Representative" },
  { value: "Representative Trustee", label: "Representative Trustee" },
  { value: "Responsible Person", label: "Responsible Person" },
  {
    value: "Company Secretary/Close Corporation",
    label: "Company Secretary/Close Corporation",
  },
  { value: "Shareholder", label: "Shareholder" },
  { value: "Signatory", label: "Signatory" },
  { value: "Testamentary Trust", label: "Testamentary Trust" },
  { value: "Trust", label: "Trust" },
  { value: "Trustee", label: "Trustee" },
  { value: "Director", label: "Director" },
  { value: "Unknown", label: "Unknown" },
  { value: "Beneficiary", label: "Beneficiary" },
  {
    value: "Anti-Money Laundering Compliance Officer (AMLCO)",
    label: "Anti-Money Laundering Compliance Officer (AMLCO)",
  },
  { value: "Founder", label: "Founder" },
  { value: "Managing Director (MD)", label: "Managing Director (MD)" },
  { value: "AML Analyst", label: "AML Analyst" },
  { value: "Partner", label: "Partner" },
  { value: "Chief Financial Officer", label: "Chief Financial Officer" },
  { value: "Not Obtained", label: "Not Obtained" },
  { value: "Resigned", label: "Resigned" },
  { value: "Associate", label: "Associate" },
  { value: "Beneficial Owner", label: "Beneficial Owner" },
  { value: "President of Company", label: "President of Company" },
  { value: "Other", label: "Other" },
];
