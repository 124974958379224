import Loader from "react-js-loader";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockClockIcon from "@mui/icons-material/LockClock";
import LockIcon from "@mui/icons-material/Lock";
import GroupIcon from "@mui/icons-material/Group";
import GroupsIcon from "@mui/icons-material/Groups";
import VerifiedIcon from "@mui/icons-material/Verified";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import UploadIcon from "@mui/icons-material/Upload";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import RefreshIcon from "@mui/icons-material/Refresh";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import CancelIcon from "@mui/icons-material/Cancel";
import CreateIcon from "@mui/icons-material/Create";
import PestControlIcon from "@mui/icons-material/PestControl";
import "./Const.css";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import SecurityUpdateIcon from "@mui/icons-material/SecurityUpdate";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ArticleIcon from "@mui/icons-material/Article";
import PublicIcon from "@mui/icons-material/Public";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import FeedbackIcon from "@mui/icons-material/Feedback";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import TopicIcon from "@mui/icons-material/Topic";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SortIcon from "@mui/icons-material/Sort";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import MailIcon from "@mui/icons-material/Mail";
import AddIcon from "@mui/icons-material/Add";
import SendIcon from "@mui/icons-material/Send";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ReplayCircleFilledIcon from "@mui/icons-material/ReplayCircleFilled";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import TuneIcon from "@mui/icons-material/Tune";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import EventNoteIcon from "@mui/icons-material/EventNote";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import Spinner from "../views/spinner/Spinner";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PersonIcon from "@mui/icons-material/Person";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

export const DRAG_AND_DROPDOWN_ICON = () => <DragIndicatorIcon />;
export const MORE_ICON = () => <MoreHorizIcon />;
export const BANK_ICON = () => (
  <AccountBalanceIcon
    style={{
      padding: "2px",
    }}
  />
);
export const INDIVIDUAL_ICON = () => (
  <PersonIcon
    style={{
      padding: "2px",
    }}
  />
);
export const PERK_SUB = () => (
  <CardMembershipIcon
    style={{
      color: "#38aee0",
      padding: "5px",
      height: "40px",
      width: "40px",
    }}
  />
);

export const CREADIT_LOG = () => <EventNoteIcon style={{ color: "#777e89" }} />;
export const MONEY_ICON = () => <AttachMoneyIcon />;
export const ROTATE_IMG = ({ CS }) => <RotateRightIcon style={CS} />;
export const REMOVE_BACKROUND = ({ CS }) => <WallpaperIcon style={CS} />;
export const REVERIFY = () => <ReplayCircleFilledIcon />;
export const ADD_CIRLCE = () => <AddCircleIcon />;
export const LOGS_ICON = () => (
  <ContactPageIcon
    style={{
      color: "#38aee0",
      padding: "5px",
      height: "40px",
      width: "40px",
    }}
  />
);
export const MINUS_ICONS = () => <RemoveCircleOutlineIcon />;
export const REPORT = ({ CS }) => (
  <ReportProblemOutlinedIcon
    style={{ height: "40px", width: "40px", color: "red" }}
  />
);
export const SEND_EMAIL = () => (
  <SendIcon
    style={{
      background: "rgb(56, 174, 224)",
      color: "white",
      height: "30px",
      width: "30px",
      borderRadius: "5px",
      padding: "5px",
    }}
  />
);

export const BACK_BUTTON = () => (
  <ArrowBackIcon
    style={{
      background: "transparent",
      color: "grey",
      height: "25px",
      width: "25px",
    }}
  />
);
export const SEND_EMAIL_ = () => (
  <SendIcon
    style={{
      background: "rgb(56, 174, 224)",
      color: "white",
      height: "15px",
      width: "15px",
      borderRadius: "5px",
      // padding: "5px",
    }}
  />
);
export const SORT_APHAPHET_ICON = () => (
  <SortIcon
    style={{
      background: "white",
      height: "35px",
      width: "35px",
      padding: "5px",
      borderRadius: "5px",
      border: "1px solid rgba(0, 0, 0, 0.25)",
    }}
  />
);
// export const FILTER_ICON = () => <FilterAltIcon />;
export const FILTER_ICON = () => (
  <TuneIcon
    style={{
      background: "white",
      height: "35px",
      width: "35px",
      padding: "5px",
      borderRadius: "5px",
      border: "1px solid rgba(0, 0, 0, 0.25)",
    }}
  />
);
export const SEARCH_ICON = () => <SearchIcon />;
export const CLOSE_ICON = () => <CloseIcon />;
export const ADD_ICON = () => <AddIcon />;
export const ADD_ICONS = () => (
  <AddIcon
    style={{
      background: "#38aee0",
      color: "white",
      height: "40px",
      width: "40px",
      borderRadius: "5px",
    }}
  />
);
export const UPLOAD_CSV = () => (
  <DriveFolderUploadOutlinedIcon
    style={{
      background: "#38aee0",
      color: "white",
      height: "40px",
      width: "40px",
      borderRadius: "5px",
      padding: "5px",
    }}
  />
);
export const DROP_DOWN = () => (
  <ArrowDropDownCircleIcon
    style={{
      background: "#38aee0",
      color: "white",
      height: "40px",
      width: "40px",
      borderRadius: "5px",
      padding: "5px",
    }}
  />
);
export const NOTE_ICON = () => (
  <NoteAltIcon
    style={{
      background: "#38aee0",
      color: "white",
      height: "40px",
      width: "40px",
      borderRadius: "5px",
      padding: "5px",
    }}
  />
);
export const QUESTIONS = () => (
  <InsertDriveFileIcon
    style={{
      background: "#38aee0",
      color: "white",
      height: "40px",
      width: "40px",
      borderRadius: "5px",
      padding: "5px",
    }}
  />
);
export const IMAGE_UPLOAD = () => <FileUploadIcon />;
export const EMAIL_ICON = () => <MailIcon />;

export const EXTRACT_ICON = ({ Color = "rgb(56, 174, 224)" }) => (
  <AssignmentReturnedIcon
    style={{
      background: Color,
      color: "white",
      height: "30px",
      width: "30px",
      borderRadius: "5px",
      padding: "5px",
    }}
  />
);
export const COPY_ICON = ({ color }) => (
  <ContentCopyIcon style={{ color: color }} />
);
export const TRY_NOW = ({ color }) => (
  <PlayArrowIcon
    style={{
      // color: color,
      background: "#38aee0",
      color: "white",
      borderRadius: "10px",
      padding: "5px",
    }}
  />
);

export const DOWNLOAD_DETAILS_ICON = ({
  customStyle = {
    background: "rgb(56, 174, 224)",
    color: "white",
    height: "30px",
    width: "30px",
    borderRadius: "5px",
    padding: "5px",
  },
}) => <DownloadForOfflineIcon style={customStyle} />;
export const GRAPH_CHART_ICON = () => (
  <AccountTreeIcon
    style={{
      background: "rgb(56, 174, 224)",
      color: "white",
      height: "30px",
      width: "30px",
      borderRadius: "5px",
      padding: "5px",
    }}
  />
);
export const REPORT_ICON = ({ Color = "rgb(56, 174, 224)" }) => (
  <ReportGmailerrorredIcon
    style={{
      background: Color,
      color: "white",
      height: "30px",
      width: "30px",
      borderRadius: "5px",
      padding: "5px",
    }}
  />
);
export const GO_ICON = () => <ArrowUpwardIcon />;
export const REFRESH = () => <RefreshIcon />;
export const SELFIVERIFY = () => <HowToRegIcon />;
export const FEEDBACK = () => <FeedbackIcon />;
export const PHONE = () => <PhoneIcon />;
export const EMIALICONS = () => <EmailIcon />;
export const EARTHICON = () => <PublicIcon />;
export const DOCUMENT = () => <ArticleIcon />;
export const LEFTARROW = () => <ChevronLeftIcon />;
export const LETTER = (hidd) => <MailOutlineIcon style={style.IconColor} />;
export const HIDEBALANCE = (hidd) => <MailOutlineIcon style={style.hide} />;
export const KEYICON = (hidd) => <VpnKeyIcon style={style.hide} />;
export const LOCK = () => <LockIcon style={style.IconColor} />;
export const OTP = () => <LockClockIcon style={style.IconColor} />;
export const CROSSICON = () => <CloseIcon style={style.IconColor} />;
export const SEARCHICON = () => <SearchIcon style={style.Blk} />;
export const GROUPICON = () => <GroupIcon style={style.SuperBigIcon} />;
export const DOWNLOADICON = () => <SecurityUpdateIcon className="GR" />;
export const DELETE_ICON = () => (
  <DeleteOutlineOutlinedIcon
    style={{
      background: "rgb(56, 174, 224)",
      color: "white",
      height: "40px",
      width: "40px",
      borderRadius: "5px",
      padding: "5px",
    }}
    id="EntityUpdate"
  />
);
export const UPDATE_ICON = () => (
  <BorderColorIcon
    style={{
      background: "rgb(56, 174, 224)",
      color: "white",
      height: "40px",
      width: "40px",
      borderRadius: "5px",
      padding: "5px",
    }}
    id="EntityUpdate"
  />
);
export const TEAMICON = () => (
  <GroupsIcon style={style.SuperBigIcon} className="dk" />
);
export const VERIFYICON = () => (
  <VerifiedIcon style={style.SuperBigIcon} className="dk" />
);
export const UPLOADICON = () => (
  <UploadIcon style={style.SuperBigIcon} className="dk" />
);
export const NOTACAMERA = () => <VideocamOffIcon className="VICON" />;
export const NOTAIMAGE = () => <ImageNotSupportedIcon className="VICON" />;
export const LIMITICONS = () => (
  <TimelapseIcon style={style.SuperBigIcon} className="dk" />
);
export const DELETEICON = () => <DeleteIcon style={style.RDD} />;
export const ICONSUCCESS = (size) => (
  <CheckCircleOutlineIcon style={size ? style.smalSize : style.Size} />
);
export const RIGHT_ICON = (size) => (
  <CheckCircleOutlineIcon style={{ color: "rgba(70, 78, 95, 1)" }} />
);
export const ICONSUCCESSBig = (size) => (
  <CheckCircleOutlineIcon style={style.bigOne} />
);
export const CANCELICON = () => <CancelIcon className="ICONSIZE" />;
export const DEBUGICON = () => <PestControlIcon className="GR" />;

export const CAMERAICON = () => <CameraAltIcon />;
export const SpinnerLoader = ({ state }) =>
  state === true ? (
    // <div style={style.LoaderPosition} className="SPINNER_LOADER">
    //   <Loader
    //     type="spinner-cub"
    //     bgColor={"#38aee0"}
    //     // title={"box-up"}
    //     color={"#38aee0"}
    //     size={100}
    //   />
    // </div>
    <div style={style.LoaderPosition} className="SPINNER_LOADER">
      <Spinner />
    </div>
  ) : null;

export const EYEICON = () => (
  <RemoveRedEyeOutlinedIcon
    style={{
      color: "#38aee0",
      padding: "5px",
      height: "40px",
      width: "40px",
    }}
  />
);

export const TRASHICON = ({ color }) => (
  <DeleteOutlineOutlinedIcon
    style={{
      color: color ? "grey" : "#38aee0",
      padding: "5px",
      height: "40px",
      width: "40px",
    }}
  />
);
export const PENCILICON = ({ color, invert }) => (
  <CreateIcon
    style={{
      color: "#38aee0",
      padding: "5px",
      height: "40px",
      width: "40px",
    }}
  />
);
export const PENCILICONBIG = ({ color, invert }) => (
  <CreateIcon
    style={{
      background: color ? "grey" : invert ? "transparent" : "#38aee0",
      color: invert ? "#38aee0" : "white",
      borderRadius: "10px",
      // padding: "5px",
    }}
  />
);
export const ADDRESSICON = ({ color, invert }) => (
  <TopicIcon
    style={{
      background: color ? "grey" : invert ? "transparent" : "#38aee0",
      color: invert ? "#38aee0" : "white",
      borderRadius: "10px",
      padding: "5px",
    }}
  />
);
export const ADDRESSICONBIG = ({ color, invert }) => (
  <TopicIcon
    style={{
      background: color ? "grey" : invert ? "transparent" : "#38aee0",
      color: invert ? "#38aee0" : "white",
      borderRadius: "10px",
      // padding: "5px",
    }}
  />
);
const style = {
  IconColor: {
    color: "#38aee0",
  },
  White: {
    color: "#fff",
  },
  Blk: {
    color: "#888888",
  },

  LoaderPosition: {
    position: "fixed",
    top: "15%",
    left: "50%",
    // right: "50%",
    // bottom: "50%",
    zIndex: "400",
  },
  Size: {
    color: "#38aee0",
    height: "90px",
    width: "90px",
  },
  bigOne: {
    height: "50px",
    width: "50px",
    color: "#38aee0",
  },
  NOTVID: {
    height: "70px",
    width: "70px",
    color: "white",
  },
  SuperBigIcon: {
    height: "50px",
    width: "50px",
    // border: "1px solid #38aee0",
    borderRadius: "10px",
    padding: "5px",
    backgroundColor: "#38aee0",
    color: "white",
  },
  smalSize: {
    backgroundColor: "#38aee0",
    color: "white",
    height: "20px",
    width: "20px",
    borderRadius: "100px",
  },
  Grey: {
    backgroundColor: "white",
    color: "rgb(205 205 205)",
    height: "20px",
    width: "20px",
    borderRadius: "100px",
    cursor: "pointer",
  },
  RDD: {
    backgroundColor: "white",
    color: "#f56969",
    height: "25px",
    width: "25px",
    borderRadius: "100px",
    cursor: "pointer",
  },
  hide: {
    visibility: "hidden",
  },
};
