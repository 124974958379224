import React, { useEffect, useRef, useState } from 'react';
import OrganizationChart from '@dabeng/react-orgchart';
import { useLocation } from 'react-router';
import './Style.css';
import { BANK_ICON, INDIVIDUAL_ICON } from '../../Global/Icons';
import { GET_DATA } from '../../Api/GetData';
import { FormControl, MenuItem, Select } from '@mui/material';
import { getStatus_Total_Score } from '../../Global/Const';
import ViewEntityTutorial from '../../CustomComponent/Tutorials/ViewEntity';
import { toggleViewEntityTutorial } from '../../redux/customizer/Action';
import { useSelector, useDispatch } from 'react-redux';
const Ornagram = () => {
  const dispatch = useDispatch();
  const isViewEntityTutorialActive = useSelector(
    (state) => state.CustomizerReducer.isViewEntityTutorialActive
  );
  const tutorialStep = useSelector(
    (state) => state.CustomizerReducer.tutorialStep
  );
  useEffect(() => {
    if (isViewEntityTutorialActive) {
      dispatch(toggleViewEntityTutorial());
    }
  }, [dispatch, isViewEntityTutorialActive]);

  const locate = useLocation();
  const [structure, setStructure] = useState({});
  const [zoom, setZoom] = useState(false);
  const [showDetail, setShowDetail] = useState(true);
  const [ubr, setUBR] = useState(false);
  const [filename, setFilename] = useState('organization_chart');
  const [fileextension, setFileextension] = useState('png');
  const [graphBackUp, setgraphBackup] = useState([]);
  const orgchart = useRef();

  const [openlink, setOpen_Link] = React.useState(false);
  const [addLink, setAddlink] = React.useState('');
  const exportTo = ({ type }) => {
    orgchart.current.exportTo(
      `${locate?.state?.businessDetail?.businessName}_${locate?.state?.businessDetail?._id}`,
      type
    );
  };

  const onNameChange = (event) => {
    setFilename(event.target.value);
  };

  const onExtensionChange = (event) => {
    setFileextension(event.target.value);
  };

  const renderNode = (nodeData) => {
    const isEntity = nodeData?.nodeData?.type == 'entity';
    const nodeClass = isEntity
      ? 'node-entity  Header_Entity'
      : 'node-individual Header_Individual';

    return (
      <div className='outterBox'>
        <div className={nodeClass}>
          {isEntity ? <BANK_ICON /> : <INDIVIDUAL_ICON />}
          {nodeData?.nodeData?.linkedId?.name &&
            `${nodeData?.nodeData?.linkedId?.name} ${nodeData?.nodeData?.linkedId?.lastName}`}

          {nodeData?.nodeData?.linkedId?.businessName}
        </div>
        {showDetail && (
          <div className='Innerbody'>
            <div>{nodeData?.nodeData?.role}</div>
            <div>
              {nodeData?.nodeData?.equityShare &&
                `${nodeData?.nodeData?.equityShare}%`}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '10px',
              }}
            >
              <div
                className={getStatus_Total_Score(
                  nodeData?.nodeData?.riskStatus
                )}
              >
                {nodeData?.nodeData?.riskStatus}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  function filterObjectsWithZeroEquityShare(obj) {
    if (obj.equityShare === 0) {
      return false; // Exclude this object
    }
    if (obj.children) {
      obj.children = obj.children.filter((child) =>
        filterObjectsWithZeroEquityShare(child)
      );
    }
    return true; // Include this object
  }

  const getOrnagramData = async () => {
    // console.log(locate, "---");
    try {
      const response = await GET_DATA(
        `entity/organograph/get/${locate?.state?.businessDetail?._id}`
      );

      let children = response?.data?.graphData;
      setgraphBackup({
        id: locate?.state?.businessDetail?._id,
        linkedId: { businessName: locate?.state?.businessDetail?.businessName },
        name: locate?.state?.businessDetail?.businessName,
        title: 'Entity',
        type: 'entity',
        children: children,
      });
      setStructure({
        id: locate?.state?.businessDetail?._id,
        linkedId: { businessName: locate?.state?.businessDetail?.businessName },
        name: locate?.state?.businessDetail?.businessName,
        title: 'Entity',
        type: 'entity',
        children: children,
      });
    } catch (e) {}
  };

  useEffect(() => {
    getOrnagramData();
  }, []);

  return (
    <div style={{ marginTop: '3%' }}>
      {isViewEntityTutorialActive && <ViewEntityTutorial step={tutorialStep} />}
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '20px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <span style={{ fontSize: '18px', fontWeight: '600' }}>
            Beneficial Owners
          </span>

          <input
            type='checkbox'
            className={'InputCheck'}
            checked={ubr}
            onChange={(e) => {
              setUBR(!ubr);
              console.log(e.target.checked);
              if (!e.target.checked) {
                getOrnagramData();
              } else {
                setStructure([]);
                const { children } = structure;
                // let check = filterZeroEquity(children);
                let filteredData = children.filter((obj) =>
                  filterObjectsWithZeroEquityShare(obj)
                );

                setStructure({
                  id: locate?.state?.businessDetail?._id,
                  linkedId: {
                    businessName: locate?.state?.businessDetail?.businessName,
                  },
                  name: locate?.state?.businessDetail?.businessName,
                  title: 'Entity',
                  type: 'entity',
                  children: filteredData,
                });
              }
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <span style={{ fontSize: '18px', fontWeight: '600' }}>Zoom</span>

          <input
            type='checkbox'
            className={'InputCheck'}
            checked={zoom}
            onChange={(e) => setZoom(!zoom)}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <span style={{ fontSize: '18px', fontWeight: '600' }}>
            Show Detail
          </span>

          <input
            type='checkbox'
            className={'InputCheck'}
            checked={showDetail}
            onChange={(e) => setShowDetail(!showDetail)}
          />
        </div>
        <div>
          <div className='flx '>
            <FormControl fullWidth>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={addLink}
                placeholder='Filter'
                onChange={(e) => {
                  setAddlink(e.target.value);
                  if (e.target.value === 'png') {
                    exportTo({ type: 'png' });
                  } else {
                    exportTo({ type: 'pdf' });
                  }
                  // Handle_Change_Status(e.target.value);
                  // setLoading(true);
                }}
                open={openlink} // Controlled by the open state
                onClose={() => setOpen_Link(false)} // Close the dropdown when clicking outside
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  // getContentAnchorEl: null,
                  classes: {
                    paper: 'select-paper',
                  },
                }}
                sx={{
                  padding: '0px',
                  height: '0px',
                  width: '0px',
                  display: 'flex',
                  alignItems: 'center',
                  visibility: 'hidden',
                }}
              >
                <MenuItem value={'png'}>PNG</MenuItem>
                <MenuItem value={'pdf'}>PDF</MenuItem>
              </Select>
            </FormControl>
          </div>
          <button
            onClick={() => setOpen_Link(true)}
            className='Active_Button '
            id='EntityOrgExport'
          >
            Export
          </button>
        </div>
      </div>
      <OrganizationChart
        datasource={structure}
        chartClass='Custom_Chart'
        NodeTemplate={renderNode}
        ref={orgchart}
        // zoominLimit={1}
        // zoomoutLimit={7}
        zoom={zoom}
        pan={true}
      />
    </div>
  );
};

export default Ornagram;
