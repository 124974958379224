import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import { useEffect, useState } from "react";
import { GET_DATA } from "../Api/GetData";
import { useDispatch } from "react-redux";
import { SET_CREADIT_SCORE } from "../redux/customizer/Action";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { ToolTipWrapper } from "../Global/ToolTipWraper";
import { countries } from "../Global/Const";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
export const Custom_Selectors = (props) => {
  const { setter, value, name, options, dis = false } = props;
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">{name}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        label={name}
        disabled={dis}
        onChange={(e) => {
          setter(e.target.value);
        }}
      >
        {options?.map((data, index) => (
          <MenuItem value={data?.value} key={index}>
            {data?.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const BasicDateTimePicker = (props) => {
  const {
    setter,
    value,
    maxdate,
    mindate,
    name,
    show,
    dis = false,
    format = "YYYY-MM-DDTHH:mm:ss",
  } = props;
  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      style={{ width: "100%", paddingTop: "0" }}
    >
      <DemoContainer components={["DateTimePicker"]} sx={{ paddingTop: "0" }}>
        <DateTimePicker
          label={name}
          ampm={false}
          views={show}
          sx={{ width: "100%" }}
          disableOpenPicker
          value={moment(value)}
          disabled={dis}
          minDate={moment(mindate)}
          maxDate={moment(maxdate)}
          format={format}
          onChange={(date) => {
            const formattedDate = moment(date).format("YYYY-MM-DDTHH:mm:ss");
            setter(formattedDate);
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};
export const Custom_Date_Picker = (props) => {
  const {
    setter,
    value,
    maxdate,
    mindate,
    name,
    show,
    dis = false,
    format = "YYYY-MM-DD",
  } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} style={{ width: "100%" }}>
      <DatePicker
        label={name}
        views={show}
        sx={{ width: "100%" }}
        value={moment(value)}
        disabled={dis}
        minDate={moment(mindate)}
        maxDate={moment(maxdate)}
        format={format}
        onChange={(date) => {
          const formattedDate = moment(date).format("YYYY-MM-DD");
          setter(formattedDate);
        }}
      />
    </LocalizationProvider>
  );
};

export const Country_Selector = (props) => {
  const { setter, value, name, dis = false } = props;

  return (
    <Autocomplete
      // sx={{ backgroundColor: "white", width: "100%" }}
      id="country-select-demo"
      options={countries}
      autoHighlight
      value={value}
      label={name}
      disabled={dis}
      onChange={(event, value) => {
        setter(value);
        // console.log(value);
      }}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.label} ({option.code}){/* +{option.phone} */}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelectChip(props) {
  const { names, title, setter, value, internalSetter, dis = false } = props;
  const theme = useTheme();
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setter(value);
    internalSetter(value);
  };

  return (
    <div>
      <FormControl sx={{}} fullWidth>
        <InputLabel id="demo-multiple-chip-label">{title}</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={value}
          disabled={dis}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label={title} />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem
              key={name?.value}
              value={name?.value}
              style={getStyles(name?.value, value, theme)}
            >
              {name?.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export function ProgreesBar({ percentage }) {
  // const percentage = 66;

  return (
    <CircularProgressbar
      value={percentage}
      styles={buildStyles({
        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
        strokeLinecap: "butt",

        // Text size
        textSize: "16px",

        // How long animation takes to go from one percentage to another, in seconds
        pathTransitionDuration: 0.5,

        // Can specify path transition in more detail, or remove it entirely
        // pathTransition: 'none',

        // Colors
        pathColor: `rgba(62, 152, 199, ${percentage / 100})`,
        textColor: "#f88",
        trailColor: "#d6d6d6",
        backgroundColor: "#3e98c7",
      })}
    />
  );
}
export function Use_Api(url) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  const fetchData = async () => {
    try {
      const response = await GET_DATA(url);
      dispatch(SET_CREADIT_SCORE(response?.data));
    } catch (err) {
      setError(err);
    }
  };

  return { loading, error, data, fetchData };
}

export const DeletreButton = ({ handlefunction, Show = "Delete" }) => (
  <ToolTipWrapper
    Component={
      <DeleteOutlineOutlinedIcon
        style={{
          color: "#38aee0",
          padding: "5px",
          height: "40px",
          width: "40px",
        }}
      />
    }
    handleFunc={() => {
      handlefunction();
    }}
    PlaceHolder={Show}
  />
);
