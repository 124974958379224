import React from "react";
import { Navigate } from "react-router-dom";
import {
  FullLayout,
  BlankLayout,
  Error,
  Hompage,
  CustomLogin,
  SubcriptionPage,
  OrganisationPage,
  UserPage,
  SubPage,
  ForgetPasswordPage,
  TeamMebmerVerified,
  ResetPassword,
  SuccessVerified,
  TwoFa,
  Invoice,
  Risk_Matrix_MAN,
  Work_Flow_Udate,
  Risk_Matrix_ENTITY,
  SUBPLANE,
  DATARETENTION,
  PAYMENT,
  PLANS,
  PLANPAYMENT,
  VERIFICATION,
  PRICINGCARDS,
  SHOWCARD,
  REGISTEREDPAYMENT,
  ROLEMANAGEMENT,
  ADDROLE,
  EDITROLE,
  VIEWROLE,
  FUISCREEN,
  DRAG_AND_DROP,
  WORK_FLOW_MANAGEMENT,
  CREADIT_PAYMENT,
  CREADIT_INVOICE,
  ADD_USER,
  BUSSINESS,
  DEV_OPTION,
  ADD_BUSSINESS,
  UPDATE_BUSSINESS,
  BUSSINESS_DETAILS,
  NOTIFICATION_PAGE,
  EMAIL_TEMPLATE,
  ADD_TEMPLATE,
  SCHEDULE,
  WEB_HOOK,
  WEB_HOOK_LOG,
  ADD_WEBHOOK_MODAL,
  CREADIT_LOGS,
  ADD_EQUITY_INDIVIDUAL,
  LOGINS_LOGS,
  FIU_LOGS,
  FLOW_ADD,
  ADD_USER_LINKED,
  SENDGRID,
  REPORT_PAGE,
  REPORT_MANAGEMENT,
  VIEW_WORKFLOW,
} from "./RouteConstant";
import Redi from "../Pages/Redi/Redi";
import Secure from "../Pages/Redi/Secure";
import { WebhookModal_Update_View } from "../Pages/Webhook/WebhookModal";
import Ornagram from "../Pages/Bussiness/Ornagram";
const Router = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to="/dashboard" /> },
      { path: "/dashboard", exact: true, element: <Hompage /> },
      { path: "/schedule", exact: true, element: <SCHEDULE /> },
      {
        path: "/profile-settings",
        exact: true,
        element: <UserPage />,
      },
      {
        path: "/team-management",
        exact: true,
        element: <SubcriptionPage />,
      },
      {
        path: "/report-management",
        exact: true,
        element: <REPORT_MANAGEMENT />,
      },
      {
        path: "/view-workflow",
        exact: true,
        element: <VIEW_WORKFLOW />,
      },
      {
        path: "/add-webhook",
        exact: true,
        element: <ADD_WEBHOOK_MODAL />,
      },
      {
        path: "/report",
        exact: true,
        element: <REPORT_PAGE />,
      },
      {
        path: "/add-flow",
        exact: true,
        element: <FLOW_ADD />,
      },
      {
        path: "/individual-management",
        exact: true,
        element: <OrganisationPage />,
      },
      {
        path: "/login-history",
        exact: true,
        element: <LOGINS_LOGS />,
      },
      {
        path: "/webhooks-logs",
        exact: true,
        element: <WEB_HOOK_LOG />,
      },
      {
        path: "/webhooks/:id",
        exact: true,
        element: <WebhookModal_Update_View />,
      },
      {
        path: "/add-individual-equity",
        exact: true,
        element: <ADD_EQUITY_INDIVIDUAL />,
      },
      {
        path: "/webhooks-management",
        exact: true,
        element: <WEB_HOOK />,
      },
      {
        path: "/add-individual",
        exact: true,
        element: <ADD_USER />,
      },
      {
        path: "/add-entity-equity",
        exact: true,
        element: <ADD_USER_LINKED />,
      },
      {
        path: "/subscription",
        exact: true,
        element: <SubPage />,
      },
      {
        path: "/organogram",
        exact: true,
        element: <Ornagram />,
      },
      {
        path: "/subscription-plans",
        exact: true,
        element: <SUBPLANE />,
      },
      {
        path: "/subscription-payment",
        exact: true,
        element: <PAYMENT />,
      },
      {
        path: "/creadit-payment",
        exact: true,
        element: <CREADIT_PAYMENT />,
      },
      {
        path: "/invoice",
        exact: true,
        element: <Invoice />,
      },
      {
        path: "/credit-logs",
        exact: true,
        element: <CREADIT_LOGS />,
      },
      {
        path: "/credit-invoice",
        exact: true,
        element: <CREADIT_INVOICE />,
      },
      {
        path: "/individual-verification-detail",
        exact: true,
        element: <VERIFICATION />,
      },
      {
        path: "/data-retention",
        exact: true,
        element: <DATARETENTION />,
      },

      {
        path: "/roles-management",
        exact: true,
        element: <ROLEMANAGEMENT />,
      },
      {
        path: "/add-roles",
        exact: true,
        element: <ADDROLE />,
      },
      {
        path: "/sendgrid",
        exact: true,
        element: <SENDGRID />,
      },
      {
        path: "/entity-management",
        exact: true,
        element: <BUSSINESS />,
      },
      {
        path: "/add-entity",
        exact: true,
        element: <ADD_BUSSINESS />,
      },
      {
        path: "/update-entity",
        exact: true,
        element: <UPDATE_BUSSINESS />,
      },
      {
        path: "/entity-details",
        exact: true,
        element: <BUSSINESS_DETAILS />,
      },
      {
        path: "/edit-roles",
        exact: true,
        element: <EDITROLE />,
      },
      {
        path: "/view-roles",
        exact: true,
        element: <VIEWROLE />,
      },
      {
        path: "/notifications",
        exact: true,
        element: <NOTIFICATION_PAGE />,
      },

      {
        path: "/fiu",
        exact: true,
        element: <FUISCREEN />,
      },
      {
        path: "/fiu-logs",
        exact: true,
        element: <FIU_LOGS />,
      },
      {
        path: "/add-workflow",
        exact: true,
        element: <DRAG_AND_DROP />,
      },
      {
        path: "/workflow-management",
        exact: true,
        element: <WORK_FLOW_MANAGEMENT />,
      },

      {
        path: "/workflow-Edit",
        exact: true,
        element: <Work_Flow_Udate />,
      },
      {
        path: "/individual-risk-matrix",
        exact: true,
        element: <Risk_Matrix_MAN />,
      },
      {
        path: "/entity-risk-matrix",
        exact: true,
        element: <Risk_Matrix_ENTITY />,
      },

      {
        path: "/developer-options",
        exact: true,
        element: <DEV_OPTION />,
      },
      {
        path: "/email-template-management",
        exact: true,
        element: <EMAIL_TEMPLATE />,
      },
      {
        path: "/email-template/:id",
        exact: true,
        element: <ADD_TEMPLATE />,
      },

      { path: "*", element: <Navigate to="/dashboard" /> },
    ],
  },

  { path: "*", element: <Secure /> },

  { path: "/team-invite/:id", element: <TeamMebmerVerified /> },
  { path: "/addteam-success", element: <SuccessVerified /> },
  { path: "/2fa/:id", element: <TwoFa /> },
  { path: "/redi/:id", element: <Redi /> },
  { path: "/cms", element: <PRICINGCARDS /> },
  // { path: "/pricing", element: <SHOWCARD /> },
  { path: "/register-payment", element: <REGISTEREDPAYMENT /> },

  {
    path: "auth",
    element: <BlankLayout />,
    children: [
      { path: "404", element: <Error /> },
      { path: "login", element: <CustomLogin /> },

      // { path: "sign-up", element: <SIGNIN /> },
      { path: "plans", element: <PLANS /> },
      { path: "payment", element: <PLANPAYMENT /> },
      {
        path: "forget-password",
        element: <ForgetPasswordPage />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      // { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
