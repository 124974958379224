import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  GET_STATUS_RESULT,
  getColorCode,
  getFinalStatus,
  isObjectEmpty,
} from "../../../Global/Const";
import { NOTE_ICON, SpinnerLoader } from "../../../Global/Icons";
import { FormControl, MenuItem, Select } from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import "../Ex.css";
import { ToolTipWrapper } from "../../../Global/ToolTipWraper";
import SanctionModal, { NoteModalSanction } from "./SanctionModal";
import ModalWraper from "../../../CustomComponent/ModalWraper";
import { GET_DATA, POST_API } from "../../../Api/GetData";
import PEP from "./PEP";
import DPEP from "./DPEP";
import SanctionPage from "./SanctionPage";
import WatchListPage from "./WatchListPage";
import { IsObject_Empty } from "../../../Global/Constant";
const SacntionScreen = ({
  open = false,
  showsanctions,
  Id,
  isSanctionRan,
  Get_Score_Data,
}) => {
  const [on, setOn] = useState("Watchlist");
  const [modalOpen, setModalOpen] = useState(false);
  const [noteModal, setNoteModal] = useState(false);
  const [open_Sort, setOpen_Sort] = React.useState(false);
  const [currentData, setCurrentData] = useState([]);
  const [filter, setFilter] = useState("");
  const [status, setStatus] = useState({});
  const [fullstatus, setFullStatus] = useState({});
  const [loader, setLoader] = useState(false);
  const [totalNotes, setTotalNotes] = useState("");
  const closeNoteModal = () => setNoteModal(true);
  const closeModal = () => setModalOpen(true);
  const [wholeNotes, setWhoelNotes] = useState("");
  const [analytic, setAnalytic] = useState({});
  const [totalCleared, Set_Total_Cleared] = useState(0);
  function SetFunction({ key, fullstatus, wholeNotes }) {
    setStatus({ ...fullstatus[key], tabName: key });
    setFilter(fullstatus?.[key]?.risk);
    setTotalNotes(wholeNotes?.[key]);
  }
  async function GET_ALL_DATA() {
    try {
      const response = await GET_DATA(`user/get/sanctions/tab/data/${Id}`);
      // console.log(response, "dsf");
      setFullStatus(response?.data?.sanctionTabs);

      // I comment this becuase we set this from header
      // setStatus({
      //   ...response?.data?.sanctionTabs?.watchlist,
      //   tabName: "watchlist",
      // });
      // setFilter(response?.data?.sanctionTabs?.watchlist?.risk);
      // setTotalNotes(response?.data?.sanctionNotes?.watchlist);
      setWhoelNotes(response?.data?.sanctionNotes);
      setAnalytic(response?.data?.sanctionAnalytics);
      Set_Total_Cleared(response?.data?.itemsApproved);
      // SET_DYNAMIC_HEADER({
      //   sactionData: response?.data?.sanctionTabs,
      //   fullstatus: response?.data?.sanctionTabs,
      //   wholeNotes: response?.data?.sanctionNotes,
      // });
    } catch (e) {}
  }

  async function handelChangeStatus(updatedStatus) {
    try {
      const response = await POST_API(
        `user/update/sanctions/risk/tab/data/${Id}`,
        {
          risk: updatedStatus,
          tabName: status?.tabName,
        },
        "Status Updated Successfully"
      );
      setLoader(false);
      GET_ALL_DATA();
    } catch (e) {}
  }
  async function handleUpdateNote(updatedNote, pdfNote) {
    try {
      const response = await POST_API(
        `user/update/sanctions/notes/tab/data/${Id}`,
        {
          note: updatedNote,
          tabName: status?.tabName,
          pdfNote,
        },
        "Analytic Note Updated Successfully"
      );
      setLoader(false);
      GET_ALL_DATA();
    } catch (e) {}
  }

  async function UpdateStatus(data) {
    try {
      const response = await POST_API(
        `user/update/sanctions/approval/tab/data/${Id}`,
        {
          approval: data,
          tabName: status?.tabName,
        },
        "Status Updated Successfully"
      );
      setLoader(false);
      GET_ALL_DATA();
      Get_Score_Data();
    } catch (e) {}
  }

  useEffect(() => {
    GET_ALL_DATA();
  }, []);

  return (
    <>
      <SpinnerLoader state={loader} />
      <ModalWraper
        open={noteModal}
        handleClose={closeNoteModal}
        setOpen={setNoteModal}
        handleTableData={() => {}}
        Component={NoteModalSanction}
        UserId={totalNotes}
        viewData={{ handleUpdateNote, setLoader }}
        dl={"delete"}
      />
      <ModalWraper
        open={modalOpen}
        handleClose={closeModal}
        setOpen={setModalOpen}
        handleTableData={() => {}}
        Component={SanctionModal}
        viewData={currentData}
        dl={"delete"}
      />
      <Accordion defaultExpanded={open}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div style={{ display: "flex", gap: "5px" }}>
            <span className="Heaar_title ">Sanction/PEP Screen Details</span>

            {getColorCode(`${totalCleared}/4`, null)}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              className="RS_COL__"
            >
              <div className="Center_Div" style={{ gap: "5px" }}>
                <span
                  style={{ marginLeft: "10px" }}
                  className={GET_STATUS_RESULT(status?.verifyScreenStatus)}
                >
                  {GET_STATUS_RESULT(status?.verifyScreenStatus)}
                </span>
                <span>{` ${
                  status?.approvalData?.approvedBy
                    ? `By ${status?.approvalData?.approvedBy}`
                    : ""
                }  ${
                  status?.approvalData?.teamName
                    ? `/${status?.approvalData?.teamName}`
                    : ""
                }`}</span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <div>
                  {" "}
                  <ToolTipWrapper
                    PlaceHolder={"Analytics"}
                    Component={<NOTE_ICON />}
                    handleFunc={() => {
                      setNoteModal(true);
                    }}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <ToolTipWrapper
                    PlaceHolder={"Change Status"}
                    Component={
                      <AutorenewIcon
                        style={{
                          background: "rgb(56, 174, 224)",
                          color: "white",
                          height: "40px",
                          width: "40px",
                          borderRadius: "5px",
                          padding: "5px",
                        }}
                      />
                    }
                    handleFunc={() => setOpen_Sort(true)}
                  />
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={filter}
                      placeholder="Filter"
                      onChange={(e) => {
                        setFilter(e.target.value);
                        handelChangeStatus(e.target.value);
                        setLoader(true);
                      }}
                      open={open_Sort} // Controlled by the open state
                      onClose={() => setOpen_Sort(false)} // Close the dropdown when clicking outside
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        classes: {
                          paper: "select-paper",
                        },
                      }}
                      sx={{
                        padding: "0px",
                        height: "0px",
                        width: "0px",
                        display: "flex",
                        alignItems: "center",
                        visibility: "hidden",
                      }}
                    >
                      <MenuItem value={"high"}>High</MenuItem>
                      <MenuItem value={"medium"}>Medium</MenuItem>
                      <MenuItem value={"low"}>Low</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="Center_Div">
                  {" "}
                  <span
                    style={{ marginLeft: "10px" }}
                    className={GET_STATUS_RESULT(status?.risk)}
                  >
                    {GET_STATUS_RESULT(status?.risk)}
                  </span>
                </div>
              </div>
            </div>
            <Header
              val={on}
              set={setOn}
              status={status}
              setStatus={setStatus}
              fullstatus={fullstatus}
              setFilter={setFilter}
              setTotalNotes={setTotalNotes}
              wholeNotes={wholeNotes}
            />
            {on === "Watchlist" && (
              <WatchListPage
                showsanctions={showsanctions?.default}
                setCurrentData={setCurrentData}
                currentData={currentData}
                setModalOpen={setModalOpen}
                setLoader={setLoader}
                status={status}
                UpdateStatus={UpdateStatus}
                analytic={analytic?.watchlist}
                isSanctionRan={isSanctionRan}
              />
            )}
            {on === "Sanction" && (
              <SanctionPage
                showsanctions={showsanctions?.sanctions}
                setCurrentData={setCurrentData}
                currentData={currentData}
                setModalOpen={setModalOpen}
                status={status}
                setLoader={setLoader}
                analytic={analytic?.sanctions}
                UpdateStatus={UpdateStatus}
                isSanctionRan={isSanctionRan}
              />
            )}
            {on === "DPEP Scan" && (
              <DPEP
                showsanctions={showsanctions?.dpeps}
                setCurrentData={setCurrentData}
                currentData={currentData}
                setModalOpen={setModalOpen}
                status={status}
                UpdateStatus={UpdateStatus}
                analytic={analytic?.dpep}
                setLoader={setLoader}
                isSanctionRan={isSanctionRan}
              />
            )}
            {on === "PEP Scan" && (
              <PEP
                showsanctions={showsanctions?.peps}
                setCurrentData={setCurrentData}
                currentData={currentData}
                setModalOpen={setModalOpen}
                status={status}
                setLoader={setLoader}
                analytic={analytic?.pep}
                UpdateStatus={UpdateStatus}
                isSanctionRan={isSanctionRan}
              />
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default SacntionScreen;

export function Header({
  val,
  set,
  status,
  setStatus,
  fullstatus,
  setFilter,
  setTotalNotes,
  wholeNotes,
}) {
  const [Run_Only_Once, set_Run_Only_Once] = useState(true);
  function SetFunction(key) {
    setStatus({ ...fullstatus[key], tabName: key });
    setFilter(fullstatus?.[key]?.risk);
    setTotalNotes(wholeNotes?.[key]);
  }

  const prioritizeAndSet = (obj) => {
    // Log the input object

    // Define the priority order
    set_Run_Only_Once(false);
    const priorityOrder = {
      pending: 1,
      rejected: 2,
      approved: 3,
    };

    const functionNames = {
      watchlist: "Watchlist",
      sanctions: "Sanction",
      pep: "PEP Scan",
      dpep: "DPEP Scan",
    };

    // Convert the object to an array of entries, sort them, and convert back to an object
    const sortedEntries = Object.entries(obj).sort((a, b) => {
      return (
        priorityOrder[a[1]?.verifyScreenStatus] -
        priorityOrder[b[1]?.verifyScreenStatus]
      );
    });

    // Create a new sorted object
    const sortedObj = Object.fromEntries(sortedEntries);

    // Log the sorted object to check

    const firstKey = sortedEntries?.[0]?.[0];

    set(functionNames[firstKey]);
    SetFunction(firstKey);

    // Return the sorted object if needed
  };

  useEffect(() => {
    if (fullstatus?.watchlist && Run_Only_Once) {
      prioritizeAndSet(fullstatus);
    }
  }, [fullstatus]);
  return (
    <div className=" clit W_F">
      <div className="Flexit Wrp R_C_L_">
        <div className={val === "Watchlist" ? "Active_Sub " : "Deactive_Sub"}>
          <button
            id="IndivSummary"
            className={
              val === "Watchlist" ? "act_Font Sub_BTN" : "dact_Font  Sub_BTN"
            }
            // className="BTN"
            onClick={() => {
              set("Watchlist");
              SetFunction("watchlist");
            }}
          >
            Watchlist
          </button>
          {getFinalStatus(fullstatus?.["watchlist"]?.verifyScreenStatus)}
        </div>
        <div className={val === "Sanction" ? "Active_Sub " : "Deactive_Sub"}>
          <button
            id="IndivSummary"
            className={
              val === "Sanction" ? "act_Font Sub_BTN" : "dact_Font  Sub_BTN"
            }
            onClick={() => {
              set("Sanction");
              SetFunction("sanctions");
            }}
          >
            Sanction
          </button>
          {getFinalStatus(fullstatus?.["sanctions"]?.verifyScreenStatus)}
        </div>
        <div className={val === "PEP Scan" ? "Active_Sub " : "Deactive_Sub"}>
          <button
            id="IndivSummary"
            className={
              val === "PEP Scan" ? "act_Font Sub_BTN" : "dact_Font  Sub_BTN"
            }
            onClick={() => {
              set("PEP Scan");
              SetFunction("pep");
            }}
          >
            PEP Scan
          </button>
          {getFinalStatus(fullstatus?.["pep"]?.verifyScreenStatus)}
        </div>
        <div className={val === "DPEP Scan" ? "Active_Sub " : "Deactive_Sub"}>
          <button
            id="IndivSummary"
            className={
              val === "DPEP Scan" ? "act_Font Sub_BTN" : "dact_Font  Sub_BTN"
            }
            onClick={() => {
              set("DPEP Scan");
              SetFunction("dpep");
            }}
          >
            DPEP Scan
          </button>
          {getFinalStatus(fullstatus?.["dpep"]?.verifyScreenStatus)}
        </div>
      </div>
    </div>
  );
}
