export const BLUE_THEME = "BLUE_THEME";
export const UPDATE_CUSTOMIZER_STATE = "UPDATE_CUSTOMIZER_STATE";
export const ACCESS_OPTIONS = "ACCESS_OPTIONS";
export const GET_USER_DATA = "GET_USER_DATA";
export const GET_CREDIT_SCORE = "GET_CREDIT_SCORE";
export const GET_PERKS_ACCESS = "GET_PERKS_ACCESS";
export const TOGGLE_SHOW_RISK_STORE = "TOGGLE_SHOW_RISK_STORE";
export const TOGGLE_WORKFLOW_TUTORIAL = "TOGGLE_WORKFLOW_TUTORIAL";
export const TOGGLE_CREATE_INDIVIDUAL_TUTORIAL =
  "TOGGLE_CREATE_INDIVIDUAL_TUTORIAL";
export const TOGGLE_VIEW_INDIVIDUAL_TUTORIAL =
  "TOGGLE_VIEW_INDIVIDUAL_TUTORIAL";
export const TOGGLE_CREATE_ENTITY_TUTORIAL = "TOGGLE_CREATE_ENTITY_TUTORIAL";
export const TOGGLE_VIEW_ENTITY_TUTORIAL = "TOGGLE_VIEW_ENTITY_TUTORIAL";
export const TOGGLE_INDIVIDUAL_ONBOARDING_TUTORIAL =
  "TOGGLE_INDIVIDUAL_ONBOARDING_TUTORIAL";
export const SET_TUTORIAL_STEP = "SET_TUTORIAL_STEP";
export const SET_INDIVIDUAL_SCORE = "SET_INDIVIDUAL_SCORE";
export const SET_ENTITY_SCORE = "SET_ENTITY_SCORE";
export const SET_PAGINATION = "SET_PAGINATION";
